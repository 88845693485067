import { createApi } from '@reduxjs/toolkit/query';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';
import { isEqual } from 'lodash';

export type GetContactsRequest = {
  filter: {
    createdAtFrom: string;
    createdAtTo: string;
    searchFilter: string;
    onlyBlacklisted: boolean;
    searchByLastUpdate: boolean;
    companyFilter: string;
    tagFilter: string[];
    assignTo: unknown;
  };
  sorting: [];
  limit: number;
  offset: number;
};

export type GetContactsResponse = {
  data: {
    id: number;
    fullName: string;
    identificationNumber: string | null;
    email: string | null;
    phoneNumber: string | null;
    mobileNumber: string | null;
    country: string | null;
    province: string | null;
    city: string | null;
    cityName: string;
    address: string | null;
    company: string | null;
    remarks: string | null;
    isNewContact: boolean;
    lastUpdate: number;
    createAt: number;
    habeasdata: boolean;
    fullTextHabeasData: string | null;
    ipClient: string | null;
    channel: string | null;
    attributes: string | null;
    blacklisted: boolean;
    totalCount: number;
    tags: string[];
    assignType: string | null;
    assignDescription: string | null;
  }[];
  paging: {
    hasMoreItems: boolean;
  };
};
export type GetContactsCountResponse = {
  count: number;
};

const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['contacts'],
  endpoints: ({ query }) => ({
    getContacts: query<GetContactsResponse, GetContactsRequest>({
      providesTags: ['contacts'],
      query: request =>
        apiResource(B2ChatClient.resources.contacts.actions.v2GetContacts, {
          data: request,
        }),
    }),
    getContactsCount: query<GetContactsCountResponse, GetContactsRequest>({
      providesTags: ['contacts'],
      serializeQueryArgs: args => args,
      forceRefetch: params => !isEqual(params.currentArg, params.previousArg),
      query: request => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { offset, ...requestWithoutOffset } = request;

        return apiResource(
          B2ChatClient.resources.contacts.actions.v2GetContactsCount,
          {
            data: requestWithoutOffset,
          }
        );
      },
    }),
  }),
});

export default contactsApi;
