/* eslint-disable import/no-named-as-default */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */

import appConfig from '@src/config/appConfig';

export type AssistantTable = {
  id: string;
  name: string;
  targetType: string; //"GENERAL",
  status: string; //"ACTIVE",
  description: string;
  channels: string[];
  createdAt: string;
  temperature: 0;
  businessProcess: number;
  vectorStoreId: string;
};

export type AssistantTableFilters = {
  limit: number;
  offset: number;
  onlyActive?: boolean; // default false
  nameLike?: string; //minimun 3 characters
};

export enum AssistantStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DRAFTED = 'DRAFTED',
}

export enum AssistantModel {
  GPT_4_0 = 'GPT_4_0',
  GPT_4_0_MINI = 'GPT_4_0_MINI',
  GPT_3_5_TURBO = 'GPT_3_5_TURBO',
}

export enum AssistantTarget {
  GENERAL = 'GENERAL',
  DEPARTMENT = 'DEPARTMENT',
}

export enum AssistantRedirects {
  SMART_REDIRECTION = 'SMART_REDIRECTION',
  NO_REDIRECTION = 'NO_REDIRECTION',
  ON_DEMAND = 'ON_DEMAND',
}

export type AssistantForm = {
  id?: string;
  name: string;
  description: string;
  status: AssistantStatus;
  instructions: string;
  model: AssistantModel;
  temperature: number;
  targetType: AssistantTarget;
  businessProcess: number;
  channels: number[];
  redirection?: AssistantRedirection[];
};

export type AssistantRedirection = {
  type: AssistantRedirects;
  description?: string;
  redirectionAttempts?: number;
};

export type AssistantFile = {
  id?: string;
  filename: string;
  usage_bytes: number;
  createdAt?: number;
};

interface FetchOptions extends RequestInit {
  headers?: Record<string, string>;
}
export function fetchAssistantApi<T>(
  url: string,
  options?: FetchOptions,
  isMultipart = false
): Promise<T> {
  const userToken = appConfig.getCurrentUserToken();

  return fetch(url, {
    ...options,
    headers: {
      ...(!isMultipart ? { 'Content-Type': 'application/json' } : {}),
      Authorization: `Bearer ${userToken.token}`,
      ...(options?.headers || {}),
    },
  }).then(response => {
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // Detect the content type
    const contentType = response.headers.get('Content-Type');

    if (contentType?.includes('application/json')) {
      return response.json() as Promise<T>;
    }

    return response.text() as Promise<T>;
  });
}
