import { Tabs, useMediaQuery } from '@material-ui/core';
import SchoolRoundedIcon from '@material-ui/icons/SchoolRounded';
import {
  fetchIntegrations,
  Integration,
  selectB2ChatJSIntegrations,
} from '@src/reducers/integrations';

import WidgetDefaultIcon from '@src/resources/images/b2chat-icon-48.png';
import { getMerchantId } from '@src/selectors/merchant';

import { fetchShopifyShop, fetchShopifyStores } from '@src/reducers/shopify';
import { getShowWsCatalogueSideMenu } from '@src/selectors/wsCatalogue';
import { useEffect, useMemo } from 'react';

import {
  getSelectedStoreId,
  hasShopifyIntegration,
} from '@src/selectors/shopify';

import { appConfig } from '@src/config/appConfig';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ShopifyShoppingIcon } from '../ShopifyViewer/SvgIcons';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChatBotIcon,
  ExclamationIcon,
  WsCatalogueOrdersIcon,
} from './SvgIcons';
import TabButton from './TabButton';

export const SideMenuTabs = {
  ToggleMenu: 0,
  Contact: 1,
  Shopify: 3,
  ChatBot: 4,
  Fluentlab: 5,
  WsCatalogue: 6,
};

const FLUENTLAB = 2199;

interface ISideMenuWrapper {
  handleToggle: () => void;
  handleSelectOption: (index: number) => void;
  selectedOption: number | string;
  isOpen: boolean;
  withChatBotHistoryOpt?: boolean;
}
export interface ISideMenu {
  selectedOption: number | string;
  isOpen: boolean;
  withChatBotHistoryOpt?: boolean;
  hasShopifyIntegra: boolean;
  merchantId: number;
  showWsCatalogueSideMenu?: boolean;
  integrations: Integration[];
  devMode: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleChange: (_event: React.ChangeEvent<{}>, newValue: number) => void;
  handleToggle: () => void;
  handleSelectOption: (index: number) => void;
  applyClass: boolean;
}

const SideMenuWrapper: React.FC<ISideMenuWrapper> = ({
  handleToggle,
  handleSelectOption,
  selectedOption,
  isOpen,
  withChatBotHistoryOpt = false,
}) => {
  const dispatch = useDispatch();
  const isMaxWidth = useMediaQuery(
    `(max-width:${appConfig.maxWidthConsole}px)`
  );

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    handleSelectOption(newValue);
    if (!isOpen) {
      handleToggle();
      handleSelectOption(newValue);
    }
  };

  const merchantId = useSelector(getMerchantId);
  const storeId = useSelector(getSelectedStoreId);
  const hasShopifyIntegra = useSelector(hasShopifyIntegration);
  const showWsCatalogueSideMenu = useSelector(getShowWsCatalogueSideMenu);
  const integrations = useSelector(selectB2ChatJSIntegrations);

  const location = useLocation();

  const devMode = useMemo(
    () => new URLSearchParams(location.search).get('widgetdev') === '1',
    [location.search]
  );

  useEffect(() => {
    dispatch(fetchIntegrations());
    dispatch(fetchShopifyStores());
  }, [dispatch, merchantId]);

  useEffect(() => {
    if (storeId) dispatch(fetchShopifyShop({ storeId }));
  }, [dispatch, storeId]);

  useEffect(() => {
    if (!devMode && selectedOption === 'widget-dev') {
      handleSelectOption(SideMenuTabs.Contact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devMode, selectedOption]);

  return (
    <SideMenu
      selectedOption={selectedOption}
      isOpen={isOpen}
      withChatBotHistoryOpt={withChatBotHistoryOpt}
      hasShopifyIntegra={hasShopifyIntegra}
      merchantId={merchantId}
      integrations={integrations}
      devMode={devMode}
      handleChange={handleChange}
      handleToggle={handleToggle}
      handleSelectOption={handleSelectOption}
      showWsCatalogueSideMenu={showWsCatalogueSideMenu}
      applyClass={isMaxWidth}
    />
  );
};

export const SideMenu: React.FC<ISideMenu> = ({
  selectedOption,
  isOpen,
  withChatBotHistoryOpt,
  hasShopifyIntegra,
  merchantId,
  showWsCatalogueSideMenu,
  integrations,
  devMode,
  handleChange,
  handleToggle,
  handleSelectOption,
  applyClass,
}) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
    }}
    className={`${applyClass ? 'side-menu-container' : ''}`}
  >
    <Tabs
      orientation="vertical"
      value={isOpen ? selectedOption : false}
      onChange={handleChange}
      indicatorColor="primary"
      TabIndicatorProps={{
        style: { display: 'none' },
      }}
    >
      <TabButton
        icon={isOpen ? ArrowRightIcon : ArrowLeftIcon}
        onClick={() => {
          handleToggle();
          if (!isOpen) handleSelectOption(SideMenuTabs.Contact);
        }}
      />
      <TabButton value={SideMenuTabs.Contact} icon={ExclamationIcon} />
      {withChatBotHistoryOpt && (
        <TabButton value={SideMenuTabs.ChatBot} icon={ChatBotIcon} />
      )}
      {hasShopifyIntegra && (
        <TabButton
          value={SideMenuTabs.Shopify}
          icon={ShopifyShoppingIcon(selectedOption === SideMenuTabs.Shopify)}
        />
      )}
      {merchantId === FLUENTLAB && (
        <TabButton
          icon={<SchoolRoundedIcon />}
          value={SideMenuTabs.Fluentlab}
        />
      )}
      {showWsCatalogueSideMenu && (
        <TabButton
          icon={WsCatalogueOrdersIcon}
          value={SideMenuTabs.WsCatalogue}
        />
      )}
      {devMode && (
        <TabButton
          value="widget-dev"
          icon={
            <img
              src={WidgetDefaultIcon}
              alt="widget-icon"
              style={{ width: 24 }}
              title="widget-dev"
            />
          }
        />
      )}
      {integrations.map(
        item =>
          item.active && (
            <TabButton
              key={item.id}
              value={item.id}
              icon={
                <img
                  src={item.config.iconUrl as string}
                  alt={item.config.name}
                  style={{ width: 24 }}
                  title={item.config.name}
                />
              }
            />
          )
      )}
    </Tabs>
  </div>
);

export default SideMenuWrapper;
