/* eslint-disable */
/* eslint-disable import/no-named-as-default */
// @ts-ignore
import { RESET_STATE } from '@actions/common';
import activeChatQuickMessages from '@reducers/activeChatQuickMessages';
// @ts-ignore
import adminHomeReducer from '@reducers/adminhome';
import ads from '@reducers/ads';
import agentConsole from '@reducers/agentconsole';
import appUpdate from '@reducers/appUpdate';
import broadcastMessages from '@reducers/broadcastMessages';
import {
  citiesDataSetReducer,
  countriesDataSetReducer,
  dialogViewerReducer,
  errorsReducer,
  forgotPasswordReducer,
  imageViewerReducer,
  loginReducer,
} from '@reducers/common';
import customFiledStatusUpdate from '@reducers/customFiledStatusUpdateReducer';
import departments from '@reducers/departments';
import exportChartDetailReducer from '@reducers/exportChartDetailReducer';
import generalProperties from '@reducers/generalProperties';
import generalSettingReducer from '@reducers/generalSettingReducer';
import getAgentListReducer from '@reducers/getAgentListReducer';
import getDefaultMessageReducer from '@reducers/getDefaultMessageReducer';
import getKeenIoApiKeyReducer from '@reducers/getKeenIoApiKeyReducer';
import loginAuthentication from '@reducers/loginAuthentication';
import logOutReducer from '@reducers/logOutReducer';
import messageAccountListReducer from '@reducers/messageAccountListReducer';
import renderBotConfigurationReducer from '@reducers/renderBotConfigurationReducer';
import satisfactionBotListReducer from '@reducers/satisfactionBotListReducer';
import satisfactionReportReducer from '@reducers/satisfactionReportReducer';
import saveBotConfigurationReducer from '@reducers/saveBotConfigurationReducer';
import singletonSession from '@reducers/singletonSession';
import standardMessages from '@reducers/standardMessages';
import uploadFiles from '@reducers/uploadFiles';
import urlPreview from '@reducers/urlPreview';
import users from '@reducers/users';
import user from '@reducers/userState';
import whatsAppValidateCampaignName from '@reducers/whatsAppValidateCampaignName.reducer';
import chatMessageQueue from '@src/reducers/chatMessageQueue';
import chatViewer from '@src/reducers/chatViewer';
import { AnyAction, combineReducers } from 'redux';
import apis from './apis';
import assistants from './assistants';
import automaticMessages from './automaticMessages';
import bannerReducer from './banner';
import botAccounts from './botAccounts';
import carouselImages from './carouselImages';
import chatHistory from './chatHistory';
import chats from './chats';
import chatsSearch from './chatSearch';
import contactVCard from './contactVCard';
import deliveriesMassive from './deliveriesMassive';
import integrations from './integrations';
import merchantPlanUsage from './merchantPlanUsage';
import partnersCustomUi from './partnersCustomUi';
import quickMessages from './quickMessages';
import requestSpecialAccount from './requestSpecialAccount';
import saveWhatsAppTemplate from './saveWhatsAppTemplate';
import websocket from './websocket';
import whatsAppProfile from './whatsAppProfile';
import whatsAppTemplates from './whatsAppTemplates';
import wsFlowEditor from './wsFlowEditor';

const appReducer = combineReducers({
  errors: errorsReducer,
  login: loginReducer,
  countriesDataSet: countriesDataSetReducer,
  forgotPassword: forgotPasswordReducer,
  citiesDataSet: citiesDataSetReducer,
  agentConsole,
  adminHome: adminHomeReducer,
  imageViewer: imageViewerReducer,
  dialogViewer: dialogViewerReducer,
  loginAuthentication,
  generalProperties,
  logOut: logOutReducer,
  satisfactionBotList: satisfactionBotListReducer,
  satisfactionReport: satisfactionReportReducer,
  messagingAccountList: messageAccountListReducer,
  renderBotConfiguration: renderBotConfigurationReducer,
  getDefaultMessage: getDefaultMessageReducer,
  getKeenIoApiKey: getKeenIoApiKeyReducer,
  getAgentList: getAgentListReducer,
  saveBotConfiguration: saveBotConfigurationReducer,
  exportChartDetail: exportChartDetailReducer,
  generalSetting: generalSettingReducer,
  customFiledStatus: customFiledStatusUpdate,
  whatsAppValidateCampaignName,
  departments,
  activeChatQuickMessages,
  quickMessages,
  standardMessages,
  uploadFiles,
  broadcastMessages,
  urlPreview,
  user,
  users,
  singletonSession,
  chatMessageQueue,
  banner: bannerReducer,
  appUpdate,
  automaticMessages,
  merchantPlanUsage,
  botAccounts,
  whatsAppProfile,
  whatsAppTemplates,
  saveWhatsAppTemplate,
  requestSpecialAccount,
  deliveriesMassive,
  chatHistory,
  ads,
  chats,
  chatsSearch,
  chatViewer,
  integrations,
  websocket,
  contactVCard,
  partnersCustomUi,
  wsFlowEditor,
  assistants,
  carouselImages,
  ...apis.reducer,
});

type State = ReturnType<typeof appReducer>;

export interface RootState extends State {}

const rootReducer = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  state: RootState | undefined | {} = {},
  action: AnyAction
) => {
  if (action.type === RESET_STATE) {
    return action.state;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return appReducer(state as any, action);
};

export default rootReducer as typeof appReducer;
