/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import { ErrorData } from '@b2chat/chat-center-sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  QuickMessageFilesFormatType,
  QuickMessageMediaFiles,
} from '@src/components/AdminHome/QuickMessages/types';
import { MediaType } from '@src/model/frontendmodel';

import { QuickMessage } from '@types';

type Loading =
  | 'fetch-quick-message-list'
  | 'remove-quick-message'
  | 'save-quick-message'
  | 'upload-files-quick-message'
  | 'send-quick-message-with-files'
  | 'enqueue-quick-message-file';

// eslint-disable-next-line @typescript-eslint/ban-types
type Filter = { botId: string; filters: object };

export type QueueQuickMessageFile = {
  type: MediaType.FILE | MediaType.IMAGE;
  file: File;
  url: string;
};

export type SaveQuickMessageItemPayload = {
  botId: number;
  quickMessage: QuickMessage;
};

export type QuickMessagesState = {
  error?: ErrorData;
  loading: Loading[];
  filter?: Filter;
  items: QuickMessage[];
  item: QuickMessage | null;
  files: QuickMessageMediaFiles;
  queueFiles: QueueQuickMessageFile[];
};

const initialState: QuickMessagesState = {
  loading: [],
  items: [],
  item: null,
  files: {
    fileDetails: [],
  },
  queueFiles: [],
};

const quickMessagesSlice = createSlice({
  initialState,
  name: 'quickMessages',
  reducers: {
    fetchQuickMessageList(state, action: PayloadAction<Filter>) {
      state.loading.push('fetch-quick-message-list');
      state.filter = action.payload;
      state.error = undefined;
      state.items = [];
    },
    fetchQuickMessageListSuccess(state, action: PayloadAction<QuickMessage[]>) {
      state.items = action.payload;
    },
    fetchQuickMessageListFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    fetchQuickMessageListFulfill(state) {
      state.loading = state.loading.filter(
        l => l !== 'fetch-quick-message-list'
      );
    },
    removeQuickMessageItem(state, action: PayloadAction<string>) {
      state.loading.push('remove-quick-message');
      const item = state.items.find(item => item.id === action.payload);
      if (item) item.hidden = true;
    },
    removeQuickMessageItemSuccess(state, action: PayloadAction<string>) {
      const index = state.items.findIndex(item => item.id === action.payload);
      if (index !== -1) state.items.splice(index, 1);
    },
    removeQuickMessageItemFailure(
      state,
      action: PayloadAction<{ id: string; error: ErrorData }>
    ) {
      // state.error = action.payload;
      const item = state.items.find(item => item.id === action.payload.id);
      if (item) item.hidden = false;
    },
    removeQuickMessageItemFulfill(state) {
      state.loading = state.loading.filter(l => l !== 'remove-quick-message');
    },
    saveQuickMessageItem(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<SaveQuickMessageItemPayload>
    ) {
      state.loading.push('save-quick-message');
      state.item = null;
    },
    saveQuickMessageItemSuccess(state, action: PayloadAction<QuickMessage>) {
      state.item = action.payload;
    },
    saveQuickMessageItemFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    saveQuickMessageItemFulfill(state) {
      state.files = { fileDetails: [] };
      state.loading = state.loading.filter(l => l !== 'save-quick-message');
    },
    editQuickMessageItem(state, action: PayloadAction<QuickMessage>) {
      state.files = action.payload.files || { fileDetails: [] };
    },
    removeQuickMessageItemFile(state, action: PayloadAction<string>) {
      state.files.fileDetails = state.files.fileDetails.filter(
        file => file.fileUrl !== action.payload
      );
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    uploadSingleFileQuickMessage(_state, _action: PayloadAction<File>) {},

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uploadFilesQuickMessage(state, _action: PayloadAction<File[]>) {
      state.loading.push('upload-files-quick-message');
    },
    uploadFilesQuickMessageSuccess(
      state,
      action: PayloadAction<
        {
          url: string;
          name: string;
          mime: string;
        }[]
      >
    ) {
      action.payload.forEach(file => {
        state.files.fileDetails.push({
          fileUrl: file.url,
          mediaType: MediaType.FILE,
          format: file.mime.includes(QuickMessageFilesFormatType.PDF)
            ? QuickMessageFilesFormatType.PDF
            : undefined,
        });
      });
    },
    uploadFilesQuickMessageFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    uploadFilesQuickMessageFulfill(state) {
      state.loading = state.loading.filter(
        l => l !== 'upload-files-quick-message'
      );
    },
    enqueueQuickMessageFiles(
      state,
      action: PayloadAction<QueueQuickMessageFile[]>
    ) {
      state.queueFiles = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendQuickMessageWithFiles(state, _action: PayloadAction<string>) {
      state.loading.push('send-quick-message-with-files');
    },
    sendQuickMessageWithFilesSuccess(state) {
      state.queueFiles = [];
    },
    sendQuickMessageWithFilesFailure(state, action: PayloadAction<ErrorData>) {
      state.error = action.payload;
    },
    sendQuickMessageWithFilesFulfill(state) {
      state.loading = state.loading.filter(
        l => l !== 'send-quick-message-with-files'
      );
    },
    cleanQuickMessageEditState(state) {
      state.item = null;
      state.files = { fileDetails: [] };
    },
  },
});

export default quickMessagesSlice.reducer;

export const {
  fetchQuickMessageList,
  fetchQuickMessageListSuccess,
  fetchQuickMessageListFailure,
  fetchQuickMessageListFulfill,
  removeQuickMessageItem,
  removeQuickMessageItemSuccess,
  removeQuickMessageItemFailure,
  removeQuickMessageItemFulfill,
  saveQuickMessageItem,
  saveQuickMessageItemSuccess,
  saveQuickMessageItemFailure,
  saveQuickMessageItemFulfill,
  editQuickMessageItem,
  removeQuickMessageItemFile,
  uploadSingleFileQuickMessage,
  uploadFilesQuickMessage,
  uploadFilesQuickMessageSuccess,
  uploadFilesQuickMessageFailure,
  uploadFilesQuickMessageFulfill,
  enqueueQuickMessageFiles,
  sendQuickMessageWithFiles,
  sendQuickMessageWithFilesSuccess,
  sendQuickMessageWithFilesFailure,
  sendQuickMessageWithFilesFulfill,
  cleanQuickMessageEditState,
} = quickMessagesSlice.actions;
