/* eslint-disable @typescript-eslint/ban-ts-comment */

function loadUsetifulScript(user: { id: number; email: string }) {
  // @ts-ignore
  window.usetifulTags = {
    userId: user.id,
    email: user.email,
  };

  const a = document.getElementsByTagName('head')[0];
  const r = document.createElement('script');
  r.async = true;
  r.src = 'https://www.usetiful.com/dist/usetiful.js';
  r.setAttribute('id', 'usetifulScript');
  r.dataset.token = 'cda31ecd3fbf5a9a149d45c35a847e72'; // This is your unique USETIFUL token. Don't change it.
  a.appendChild(r);
}

export default loadUsetifulScript;
