/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
  CarouselImages,
  fetchCarouselImages,
  fetchCarouselImagesFailure,
  fetchCarouselImagesFulfill,
  fetchCarouselImagesSuccess,
} from '@src/reducers/carouselImages';
import { ErrorData } from '@types';

function* fetchCarouselImagesSaga(
  action: ReturnType<typeof fetchCarouselImages>
) {
  const { isFromAmerica } = action.payload;
  const url = `https://s3.amazonaws.com/static.b2chat/prd/carousel-login${
    isFromAmerica ? '' : '-EU'
  }.json`;
  try {
    const response: Response = yield call(fetch, url);
    const data: CarouselImages = yield response.json();
    yield put(fetchCarouselImagesSuccess(data));
  } catch (error) {
    yield put(fetchCarouselImagesFailure(error as ErrorData));
  } finally {
    yield put(fetchCarouselImagesFulfill());
  }
}

export default function* carouselImagesSaga() {
  yield takeLatest(fetchCarouselImages, fetchCarouselImagesSaga);
}
