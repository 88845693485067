import { createApi } from '@reduxjs/toolkit/dist/query/react';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';

export type GetWhatsAppSettingsResponse = {
  templateSettings: {
    allowCategoryChange: boolean;
    marketingTtl?: number;
    utilityTtl?: number;
    authenticationTtl?: number;
  };
};

export type UpdateWhatsAppSettingsRequest = {
  templateSettings: {
    allowCategoryChange: boolean;
    marketingTtl?: number;
    utilityTtl?: number;
    authenticationTtl?: number;
  };
};

const convertTtl = (ttl: number | undefined, divisor: number) =>
  (ttl ?? 0) / divisor || 0;

export const whatsAppApi = createApi({
  reducerPath: 'whatsapp',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['whatsapp-settings'],
  endpoints: ({ query, mutation }) => ({
    getWhatsAppSettings: query<GetWhatsAppSettingsResponse, void>({
      providesTags: ['whatsapp-settings'],
      query: () =>
        apiResource(
          B2ChatClient.resources.whatsapp.actions.retrieveSettings,
          {}
        ),
      transformResponse: (response: GetWhatsAppSettingsResponse) => {
        const {
          allowCategoryChange,
          marketingTtl,
          utilityTtl,
          authenticationTtl,
        } = response.templateSettings;

        return {
          templateSettings: {
            allowCategoryChange,
            marketingTtl: convertTtl(marketingTtl, 60 * 60 * 60),
            utilityTtl: convertTtl(utilityTtl, 60 * 60),
            authenticationTtl: convertTtl(authenticationTtl, 60),
          },
        };
      },
    }),
    updateWhatsAppSettings: mutation<void, UpdateWhatsAppSettingsRequest>({
      invalidatesTags: ['whatsapp-settings'],
      query: data =>
        apiResource(B2ChatClient.resources.whatsapp.actions.updateSettings, {
          data,
        }),
    }),
  }),
});

export const {
  useGetWhatsAppSettingsQuery,
  useLazyGetWhatsAppSettingsQuery,
  useUpdateWhatsAppSettingsMutation,
} = whatsAppApi;
