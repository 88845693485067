/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NewTag } from '@src/components/AdminHome/Tags/utils';
import { MessagingProvider } from '@src/model/frontendmodel';
import { ChatSideType } from '@src/types/chats';
import {
  FilterBotAccount,
  FilterBotAccounts,
  FilterChannel,
  FilterChannels,
  FilterDepartmentNode,
  FilterDepartments,
  FilterTag,
  FilterTags,
} from '@src/types/chatsSearch';
import type { Chat } from '@types';

export type ChatType = 'ongoing' | 'incoming';

export type ChatItems = {
  [chatId: string]: Chat;
};

export type ChatSearchState = {
  filters: {
    [chatSide: string]: ChatsFilters;
  };
  departments?: FilterDepartments;
  botAccounts: FilterBotAccounts;
  tags: FilterTags;
  channels: FilterChannels;
};

export type ChatsFilters = {
  departmentNodes?: FilterDepartmentNode[];
  botAccounts: FilterBotAccount[];
  tags: FilterTag[];
  contactTags?: NewTag[];
  channels: FilterChannel[];
  windowLimit?: boolean;
  conversationLimit?: boolean;
  noAnswerFromAgent?: boolean;
  dateFrom?: Date;
  dateTo?: Date;
  contactName: string;
  noAnswerFromAgentWithinTimeLimit?: boolean;
  dateContactLastMsgFrom?: Date;
  dateContactLastMsgTo?: Date;
};

type ChatSearchReducer<P = void> = (
  state: ChatSearchState,
  action: PayloadAction<P>
) => void;

const initialState: ChatSearchState = {
  filters: {},
  botAccounts: [],
  tags: [],
  departments: undefined,
  channels: [
    {
      label: 'Instagram',
      value: MessagingProvider.INSTAGRAM,
    },
    {
      label: 'Messenger',
      value: MessagingProvider.FACEBOOK,
    },
    {
      label: 'LiveChat',
      value: MessagingProvider.LIVECHAT,
    },
    {
      label: 'Telegram',
      value: MessagingProvider.TELEGRAM,
    },
    {
      label: 'WhatsApp',
      value: [
        MessagingProvider.WHATSAPP,
        MessagingProvider.WHATSAPP360,
        MessagingProvider.WHATSAPPBM,
        MessagingProvider.WHATSAPPCHAT,
        MessagingProvider.WHATSAPPOFF,
        MessagingProvider.WHATSAPPWEB,
        MessagingProvider.WHATSAPPB2CHAT,
      ],
    },
  ],
};

const changeChatsFilters: ChatSearchReducer<{
  filters: Partial<ChatsFilters>;
  chatSide: ChatSideType;
}> = (state, { payload: { filters, chatSide } }) => {
  state.filters[chatSide] = {
    ...state.filters[chatSide],
    ...filters,
  };
};

const cleanChatsFilters: ChatSearchReducer<ChatSideType> = (
  state: ChatSearchState,
  { payload: chatSide }
) => {
  if (state.filters[chatSide]) delete state.filters[chatSide];
};

// GET DATA FILTERS
const fetchDepartments = () => {};
const fetchDepartmentsSuccess: ChatSearchReducer<FilterDepartments> = (
  state,
  { payload: departments }
) => {
  state.departments = departments;
};
const fetchDepartmentsFailure = () => {};

const fetchTags = () => {};
const fetchTagsSuccess: ChatSearchReducer<FilterTags> = (
  state,
  { payload: tags }
) => {
  state.tags = tags;
};
const fetchTagsFailure = () => {};

const fetchBotAccounts = () => {};
const fetchBotAccountsSuccess: ChatSearchReducer<FilterBotAccounts> = (
  state,
  { payload: botAccounts }
) => {
  state.botAccounts = botAccounts;
};
const fetchBotAccountsFailure = () => {};

const chatSearchSlice = createSlice({
  name: 'ChatSearch',
  initialState,
  reducers: {
    changeChatsFilters,
    cleanChatsFilters,
    fetchDepartments,
    fetchDepartmentsSuccess,
    fetchDepartmentsFailure,
    fetchTags,
    fetchTagsSuccess,
    fetchTagsFailure,
    fetchBotAccounts,
    fetchBotAccountsSuccess,
    fetchBotAccountsFailure,
  },
});

const { actions, reducer } = chatSearchSlice;

export const chatSearchActions = actions;

export default reducer;
