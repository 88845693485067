import { RootState } from '@types';

// eslint-disable-next-line import/prefer-default-export
export const assistantsTableSelector = (state: RootState) =>
  state.assistants.assistantsTable;

export const assistantsTableHasMoreSelector = (state: RootState) =>
  state.assistants.assistantsTableHasMore;

// eslint-disable-next-line import/prefer-default-export
export const assistantsConfigSelector = (state: RootState) =>
  state.assistants.assistantsConfig;

export const assistantsConfigIdSelector = (state: RootState) =>
  state.assistants.assistantsConfig?.id;

export const assistantFilesSelector =
  (state: RootState) => (assistantId: string) =>
    state.assistants?.assistantFiles[assistantId]?.files || [];

export const assistantStoreIdSelector =
  (state: RootState) => (assistantId: string) =>
    state.assistants?.assistantFiles[assistantId]?.storeId || '';

export const assistantFilesQueueSelector = (state: RootState) =>
  state.assistants?.assistantFilesQueue || [];

export const assistantStatusSelector = (state: RootState) =>
  state.assistants.assistantStatus;

export const assistantFilesStatusSelector =
  (state: RootState) => (assistantId: string) =>
    state.assistants?.assistantFiles[assistantId]?.status || 'idle';
