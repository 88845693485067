/* eslint-disable import/prefer-default-export */
import { RootState } from '@src/types';

export const selectWhatsAppTemplates = (state: RootState) =>
  state.whatsAppTemplates;

export const selectSaveWhatsAppTemplate = (state: RootState) =>
  state.saveWhatsAppTemplate;

export const selectDefaultWhatsAppTemplateByContactId = (state: RootState) => {
  const { contactTemplates } = state.whatsAppTemplates;

  const defaultTemplates = contactTemplates.filter(
    temp => temp.templatedMsg.defaultToResume
  );

  let initialTemplate;

  if (defaultTemplates.length > 0) {
    [initialTemplate] = defaultTemplates;
  } else {
    [initialTemplate] = contactTemplates;
  }
  return initialTemplate;
};
