/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';

export type GetPartnerConfigResponse = {
  partnerId: number;
  companyName: string;
  partnerType: string;
  firstLevel: boolean;
  partnerCode: string;
  country: string;
  city: string;
  createdAt: string;
  status: string;
  primaryContactName: string;
  primaryContactEmail: string;
  primaryContactPhone: string;
  secondaryContactName: string;
  secondaryContactEmail: string;
  secondaryContactPhone: string;
  partnerNotes: string;
  adminEmail: string;
  billingType: 'partner_billing' | 'b2chat_billing';
};

export const partnerApi = createApi({
  reducerPath: 'partnerApi',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['partner'],
  endpoints: builder => ({
    getPartnerConfig: builder.query<GetPartnerConfigResponse, void>({
      providesTags: ['partner'],
      keepUnusedDataFor: 10_000,
      extraOptions: { maxRetries: 0 },
      query: () =>
        apiResource(B2ChatClient.resources.partners.actions.getConfig, {
          transformError: () => ({ data: { billingType: 'b2chat_billing' } }),
        }),
    }),
  }),
});

export const { useGetPartnerConfigQuery, useLazyGetPartnerConfigQuery } =
  partnerApi;
