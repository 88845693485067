/* eslint-disable import/no-cycle */
import { MediaType, MessagingProvider } from '@src/model/frontendmodel';
import { SaveQuickMessageItemPayload } from '@src/reducers/quickMessages';
import { GoogleMapLocation } from '@types';
import { IntlShape } from 'react-intl';
import { WhasAppFlowItem } from '../Channels/WhatsApp/WhatsAppFlows/types';

// eslint-disable-next-line no-shadow
export enum QuickMessageFilesFormatType {
  PDF = 'pdf',
}

// eslint-disable-next-line no-shadow
export enum QuickMessageType {
  TEXT = 'text',
  LOCATION = 'location',
  FLOW = 'flow',
  FILES = 'files',
}

// eslint-disable-next-line no-shadow
export enum QuickMessageFlowPayloadType {
  HEADER = 'Header',
  BODY = 'Body',
  FOOTER = 'Footer',
}

export type QuickMessageFormState = {
  selectedDepartment: number | null;
  id: string | null;
  command: string;
  active: boolean;
  errors: Partial<{
    formError: boolean;
    department: boolean;
    command: boolean;
    message: boolean;
    location: boolean;
    flowBody: boolean;
    flowCta: boolean;
    flowId: boolean;
    files: boolean;
  }>;
  type: QuickMessageType;
  message?: string;
  flowHeader?: string;
  flowBody?: string;
  flowFooter?: string;
  flowId?: string;
  flowCta?: string;
  wabaId?: string;
  location?: Partial<GoogleMapLocation> | null;
  files?: QuickMessageMediaFiles;
  tmpFiles?: File[];
};

export type QuickMessageMediaFiles = {
  fileDetails: {
    fileUrl: string;
    mediaType: MediaType.FILE | MediaType.IMAGE;
    format?: QuickMessageFilesFormatType;
  }[];
};

export type WhatsAppAccount = {
  id: number;
  username: string;
  botId: number;
  provider: keyof MessagingProvider;
  alias: string;
  status: 'ACTIVE' | 'INACTIVE';
  identifier: string;
  nameSpace: string;
  wabaId: string;
  broadcastOnly: boolean;
  notSuspended: boolean;
  officialWSProvider: boolean;
  active: boolean;
};

export type QuickMessageFormProps = {
  quickMessage?: {
    id: string;
    command: string;
    active: boolean;
    type: QuickMessageType;
    message?: string;
    location?: GoogleMapLocation | null;
    belongsTo: { id: number };
    // eslint-disable-next-line camelcase
    flow_data?: QuickMessageFlowData;
    files?: QuickMessageMediaFiles;
    tmpFiles?: File[];
  };
  open: boolean;
  onCancel(): void;
  fetchWaAccounts(): void;
  whatsAppAccounts: WhatsAppAccount[];
  saveQuickMessageItem(params: SaveQuickMessageItemPayload): void;
  fetchCommerceWsFlows(): void;
  commerceWsFlows: WhasAppFlowItem[];
} & { intl: IntlShape; saveQuickMessageLoading?: boolean };

export type QuickMessageFlowData = {
  flowId: string;
  flowCta: string;
  wabaId: string;
  message: {
    header?: {
      text: string;
    };
    body: {
      text: string;
    };
    footer?: {
      text: string;
    };
  };
  // eslint-disable-next-line camelcase
} & { waba_id?: string };
