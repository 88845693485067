import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type audioRecordingState = {
  isRecording: boolean;
};

type audioRecordingReducer<P = void> = (
  state: audioRecordingState,
  action: PayloadAction<P>
) => void;

const initialState: audioRecordingState = {
  isRecording: false,
};

const handleSetAudioRecording: audioRecordingReducer<boolean> = (
  state,
  action
) => {
  state.isRecording = action.payload;
};

const audioRecordingSlice = createSlice({
  name: 'audioRecordingSlice',
  initialState,
  reducers: {
    setAudioRecording: handleSetAudioRecording,
  },
});

const { actions, reducer } = audioRecordingSlice;

export const { setAudioRecording } = actions;

export default reducer;
