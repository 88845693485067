import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData } from '@types';

type Loading = 'idle' | 'loading' | 'success' | 'failure' | 'fullfil';

export type URLPreview = {
  url: string;
  urlImage: string;
  title: string;
  description: string;
};

export type UrlItems = {
  [k: string]: URLPreview;
};

type UrlPreviewState = {
  error: ErrorData | null;
  loading: Loading;
  items: UrlItems;
  showUrlPreview: boolean;
};

type UrlPreviewReducer<P = void> = (
  state: UrlPreviewState,
  action: PayloadAction<P>
) => void;

const initialState: UrlPreviewState = {
  error: null,
  loading: 'idle',
  showUrlPreview: false,
  items: {},
};

type FetchUrlPayload = {
  url: string;
};

const handleFetchUrl: UrlPreviewReducer<FetchUrlPayload> = state => {
  state.loading = 'loading';
  state.error = null;
};

const handleFetchUrlSuccess: UrlPreviewReducer<URLPreview> = (
  state,
  action
) => {
  const item = action.payload;
  const { url } = item;
  if (state.items) {
    state.items[url] = item;
  } else {
    state.items = {
      url: item,
    };
  }
  state.loading = 'success';
};

const handleFetchUrlFailure: UrlPreviewReducer<ErrorData> = (state, action) => {
  state.error = action.payload;
  state.loading = 'failure';
};

const handleFetchUrlFulfill: UrlPreviewReducer = state => {
  state.loading = 'fullfil';
};

const handleCheckPossibleUrl: UrlPreviewReducer<string> = state => {
  state.loading = 'loading';
};

const handleSetShowUrlPreview: UrlPreviewReducer<boolean> = (state, action) => {
  state.showUrlPreview = action.payload;
};

const urlPreviewSlice = createSlice({
  name: 'UrlPreview',
  initialState,
  reducers: {
    fetchUrl: handleFetchUrl,
    fetchUrlSuccess: handleFetchUrlSuccess,
    fetchUrlFailure: handleFetchUrlFailure,
    fetchUrlFulfill: handleFetchUrlFulfill,
    checkPossibleUrl: handleCheckPossibleUrl,
    setShowUrlPreview: handleSetShowUrlPreview,
  },
});

const { actions, reducer } = urlPreviewSlice;

export const {
  fetchUrl,
  fetchUrlSuccess,
  fetchUrlFailure,
  fetchUrlFulfill,
  checkPossibleUrl,
  setShowUrlPreview,
} = actions;

export default reducer;
