import {
  APIClient,
  APIClientSpec,
  ClientResponse,
  Options as ClientResourceOptions,
} from '@b2chat/chat-center-sdk';
import { miniSerializeError, SerializedError } from '@reduxjs/toolkit';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn, retry } from '@reduxjs/toolkit/dist/query/react';
import env from '@utils/env';
import _ from 'lodash';

export { APIClient, APIClientSpec };

const spec = _.merge({}, APIClientSpec, {
  baseURL: env.WEB_SERVICE_BASE_URI,
  logRequests: env.APP_ENV !== 'production',
});

const B2ChatClient = new APIClient<typeof spec>(spec);

const spec2 = _.merge({}, APIClientSpec, {
  baseURL: env.WEB_SERVICE_BROADCAST_BASE_URI,
  logRequests: env.APP_ENV !== 'production',
});

export const B2ChatClientBroadcast = new APIClient<typeof spec2>(spec2);

const spec3 = _.merge({}, APIClientSpec, {
  baseURL: env.WEB_SERVICE_ECOMMERCE_URI,
  logRequests: env.APP_ENV !== 'production',
});

export const B2ChatClientEcommerce = new APIClient<typeof spec3>(spec3);

const spec4 = _.merge({}, APIClientSpec, {
  baseURL: env.WEB_SERVICE_CONTACTS_BASE_URI,
  logRequests: env.APP_ENV !== 'production',
});

export const B2ChatClientContacts = new APIClient<typeof spec4>(spec4);

const spec5 = _.merge({}, APIClientSpec, {
  baseURL: env.WEB_SERVICE_ASSISTANTS_BASE_URI,
  logRequests: env.APP_ENV !== 'production',
});

export const B2ChatClientAssistants = new APIClient<typeof spec5>(spec5);

export default B2ChatClient;

export type ClientResource = (
  options?: ClientResourceOptions
) => ClientResponse;

export type BaseQueryArgs = {
  resource: ClientResource;
  options: ClientResourceOptions & {
    /**
     * A function to transform an error result into a desired format.
     * 
     * @param error - The original error result or serialized error object.
     * @returns The transformed error query or data query return value.
     */
    transformError?: (
      error: QueryErrorResult | SerializedError
    ) => QueryReturnValue;
  };
};

type QueryErrorResult = {
  status: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string | number, any>;
};

const QueryErrorResultSym = Symbol.for('QueryErrorResult');

export const b2chatBaseQuery = retry((async (args: BaseQueryArgs, api) => {
  const { resource } = args;
  const { transformError, ...options } = args.options;

  try {
    options.signal = api.signal;
    const response = await resource(options);

    if (response.error) {
      const error = {
        brand: QueryErrorResultSym,
        status: response.error.status,
        data: response.error,
      } as QueryErrorResult;

      if (transformError) return transformError(error);
      return { error };
    }
    return { data: response.data };
  } catch (unknownError) {
    const error = miniSerializeError(unknownError);

    if (transformError) return transformError(error);
    return { error };
  }
}) as BaseQueryFn<BaseQueryArgs>);

export function apiResource(
  resource: ClientResource,
  options: BaseQueryArgs['options']
) {
  return { resource, options };
}

export function isQueryErrorResult(error: unknown): error is QueryErrorResult {
  return (error as { brand: symbol })?.brand === QueryErrorResultSym;
}
