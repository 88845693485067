/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { createAction } from '@reduxjs/toolkit';
// @ts-ignore
import { appConfig } from '@src/config/appConfig';
// eslint-disable-next-line import/no-cycle
import { ErrorData, LoginSuccess } from '@src/types';
import { Dispatch } from 'redux';
// @ts-ignore
// eslint-disable-next-line import/no-cycle
import { fetchPartnersCustomUiConfig } from '@src/reducers/partnersCustomUi';
import loadUsetifulScript from '@src/utils/loadUsetifulScript';
import BackendProxy from '../logic/backendproxy';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_AUTHENTICATION_REQUEST = 'LOGIN_AUTHENTICATION_REQUEST';
export const LOGIN_AUTHENTICATION_FAIL = 'LOGIN_AUTHENTICATION_FAIL';
export const LOGGED_IN_USER_RELOADED = 'LOGGED_IN_USER_RELOADED';
export const STARTED_RELOADING_USER = 'STARTED_RELOADING_USER';
export const FAILED_RELOADING_USER = 'FAILED_RELOADING_USER';

export function startLoginActionCreator() {
  return {
    type: LOGIN_START,
  };
}

export function loginActionCreator(data: LoginSuccess) {
  return {
    type: LOGIN_AUTHENTICATION_REQUEST,
    data,
  };
}

export function loginFailureAction(data: ErrorData) {
  return {
    type: LOGIN_AUTHENTICATION_FAIL,
    data,
  };
}

export const loginAuthenticationFulfill = createAction(
  'LOGIN_AUTHENTICATION_FULFILL'
);

export function loggedInUserReloaded(user: { isUserReloading: boolean }) {
  return {
    type: LOGGED_IN_USER_RELOADED,
    user,
  };
}

export function startReloadingCurrentUser() {
  return {
    type: STARTED_RELOADING_USER,
  };
}

export function failedReloadingCurrentUser() {
  return {
    type: FAILED_RELOADING_USER,
  };
}

export function loginAuthentication(
  userName: string,
  password: string,
  rememberMe?: boolean
) {
  return (dispatch: Dispatch) => {
    const MAX_INTENTS = 2;
    let loginAuthenticationIntents = 0;

    dispatch(startLoginActionCreator());

    function loginAuthenticationCallback() {
      loginAuthenticationIntents += 1;
      BackendProxy.loginAuthentication(
        userName,
        password,
        rememberMe,
        (user: LoginSuccess) => {
          dispatch(loginActionCreator(user));
          dispatch(fetchPartnersCustomUiConfig());

          // NOTE: remove this line to disable Usetiful tour
          loadUsetifulScript({ id: user.id, email: user.email });
        },
        (error: ErrorData) => {
          // eslint-disable-next-line no-console
          console.log('Login auth failed with error: ', error);
          if (loginAuthenticationIntents === MAX_INTENTS) {
            dispatch(loginFailureAction(error));
          } else {
            loginAuthenticationCallback();
          }
        }
      );
    }

    loginAuthenticationCallback();
  };
}

export function reloadAuthenticatedUser() {
  return (dispatch: Dispatch) => {
    dispatch(startReloadingCurrentUser());

    BackendProxy.reloadCurrentUser()
      .then((user: { isUserReloading: boolean }) => {
        appConfig.setCurrentUser(user);
        dispatch(loggedInUserReloaded(user));
      })
      .catch((errorDetails: Error) => {
        // eslint-disable-next-line no-console
        console.error('User reload failed', errorDetails);
        dispatch(failedReloadingCurrentUser());
      });
  };
}
