/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { WhatsAppTemplateFormValues } from '@src/components/AdminHome/Channels/WhatsApp/WhatsAppTemplates/types';

// eslint-disable-next-line import/no-cycle
import { type ErrorPayload } from '@src/types';

export type SaveWhatsAppTemplateState = {
  loading: ('pending' | 'success' | 'failure' | 'fulfill')[];
  error?: ErrorPayload;
  result: {
    templateName: string;
    category: 'MARKETING' | 'AUTHENTICATION' | 'UTILITY' | (string & {});
    status: 'PENDING' | 'REJECTED' | 'APPROVED' | (string & {});
  };
};

const initialState: SaveWhatsAppTemplateState = {
  loading: [],
  result: {
    templateName: '',
    category: '',
    status: '',
  },
};

const saveWhatsAppTemplateSlice = createSlice({
  name: 'saveWhatsAppTemplate',
  initialState,
  reducers: {
    saveWhatsAppTemplate(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<{
        accountId: string | number;
        provider?: string;
        formValues: WhatsAppTemplateFormValues;
      }>
    ) {
      state.loading = ['pending'];
    },
    saveWhatsAppTemplateStatus(
      state,
      _action: PayloadAction<{
        accountId: string | number;
        name: string;
        state: 'ACTIVE' | 'INACTIVE';
      }>
    ) {
      state.loading = ['pending'];
      state.error = undefined;
    },
    saveWhatsAppTemplateSuccess(
      state,
      action: PayloadAction<SaveWhatsAppTemplateState['result']>
    ) {
      state.loading.push('success');
      state.result = action.payload;
    },
    saveWhatsAppTemplateFailure(
      state,
      action: PayloadAction<{ error: ErrorPayload }>
    ) {
      state.loading.push('failure');
      state.error = action.payload.error;
      state.result = initialState.result;
    },
    saveWhatsAppTemplateFulfill(state) {
      state.loading = [
        ...state.loading.filter(v => v !== 'pending'),
        'fulfill',
      ];
    },
    resetSaveWhatsAppTemplate(state) {
      state.loading = [];
      state.error = undefined;
    },
  },
});

export default saveWhatsAppTemplateSlice.reducer;

export const {
  saveWhatsAppTemplateStatus,
  saveWhatsAppTemplate,
  saveWhatsAppTemplateFailure,
  saveWhatsAppTemplateFulfill,
  saveWhatsAppTemplateSuccess,
  resetSaveWhatsAppTemplate,
} = saveWhatsAppTemplateSlice.actions;
