import { createApi } from '@reduxjs/toolkit/dist/query/react';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';

type SubscriptionByMerchantResponse = {
  id: number;
  createdAt: number;
  subscriptionDate: Date | null;
  concept: string | null;
  plainConcept: string | null;
  paymentType: 'TC-AUTOMATIC' | 'OTHER';
  currency: 'USD' | 'COP';
  mmr: number;
  merchant: {
    id: number;
    name: string;
    country: {
      isoCode: string;
      name: string;
      languageCode: string;
    };
    plan: {
      code: string;
      name: string;
      maxUsers: number;
      description: string;
      maxChannels: number;
      status: string | null;
      basic: boolean;
    };
    language: string;
    state: string;
    startDate: number;
    duration: number;
    city: string;
    auditConstraint: boolean;
    auditExportConstraint: boolean;
    allowContactsExport: boolean;
    zohoUser: string | null;
    exclusiveLgWapAccounts: number;
    apiCredentials: string | null;
    botApiBrokerId: number | null;
    botApiBrokerStatus: number;
    paymentValue: number | null;
    currency: string | null;
    zoneIdName: string;
    activeState: boolean;
    trialState: boolean;
    expired: boolean;
    trialRemainingDays: number;
    zoneOffset: string;
  };
  user: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  creditCardSubscriptions: any[];
  supportAddon: boolean;
  apiIntegrationAddon: boolean;
  maintenanceAmount: number;
  discount: number;
  billingType: string;
  subscriptionDetails: null;
  doNotBillNextPeriod: boolean;
  mmrAsString: string;
  automaticPaymentType: boolean;
  automaticBilling: boolean;
  manualPaymentType: boolean;
  otherPaymentType: boolean;
  currencyCop: boolean;
};

export const merchantApi = createApi({
  reducerPath: 'merchantApi',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['merchant'],
  endpoints: builder => ({
    subscriptionByMerchant: builder.query<SubscriptionByMerchantResponse, void>(
      {
        providesTags: ['merchant'],
        keepUnusedDataFor: 10_000,
        query: () =>
          apiResource(
            B2ChatClient.resources.merchant.actions.subscriptionByMerchant,
            {}
          ),
      }
    ),
  }),
});

export const {
  useLazySubscriptionByMerchantQuery,
  useSubscriptionByMerchantQuery,
} = merchantApi;
