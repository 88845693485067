/* eslint-disable import/no-named-as-default */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-duplicates */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// @ts-ignore
import { LOGGING_OUT } from '@actions/common';
import B2ChatClient from '@client-sdk';
import {
  activateSession,
  deactivateSession,
  suspendSession,
} from '@reducers/singletonSession';
import { loginActionCreator } from '@src/actions/loginAuthenticationAction';
import appConfig from '@src/config/appConfig';
import globalHistory from '@src/globalHistory';
import { LoginSuccess } from '@src/types';
import { B2ChatAPI } from '@src/types/api';
import { call, delay, fork, put, takeLeading } from 'redux-saga/effects';
import { LOGGED_IN_USER_RELOADED } from '../actions/loginAuthenticationAction';
import {
  createStorageChannelEvent,
  emitStorageEvent,
  StorageChannelEvent,
} from './storageChannelEvent';

const IGNORED_PATHS = ['/viewer'];
const SUSPEND_NOTIFICATION = 'notification:suspend';
const LOGGING_OUT_NOTIFICATION = 'notification:logging-out';

const ignoreCurrentPath = () => {
  const currentPath = globalHistory.location.pathname;
  return IGNORED_PATHS.some(
    path => currentPath.substring(0, path.length) === path
  );
};

function* suspendSiblingTabs() {
  if (document.visibilityState === 'visible' && !ignoreCurrentPath()) {
    yield call(emitStorageEvent, SUSPEND_NOTIFICATION);
  }
  yield call(checkSession);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* suspendCurrentTab(_: StorageChannelEvent) {
  if (!ignoreCurrentPath()) {
    yield put(suspendSession());
    yield call(checkSession);
  }
}

function* logoutSiblingTabs() {
  yield call(emitStorageEvent, LOGGING_OUT_NOTIFICATION);
}

function* logoutCurrentTab() {
  yield call(checkSession);
}

function* deactivateCurrentTab() {
  // eslint-disable-next-line no-restricted-globals
  yield call([location, location.assign], 'https://www.b2chat.io');
}

function* checkSession() {
  try {
    const authorities: B2ChatAPI.Response<LoginSuccess> = yield call(
      B2ChatClient.resources.user.actions.getCurrentUser
    );

    if (authorities.data) {
      yield checkTokenSession();
      yield put(loginActionCreator(authorities.data));

      // eslint-disable-next-line no-restricted-globals
      if (['/login', '/'].includes(location.pathname)) {
        if (authorities.data.admin) {
          // eslint-disable-next-line no-restricted-globals
          yield call([history, history.pushState], null, '', '/admin/audit');
        }
        if (authorities.data.agent) {
          // eslint-disable-next-line no-restricted-globals
          yield call([history, history.pushState], null, '', '/agent/chat');
        }
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

function* checkTokenSession() {
  const MINUTE = 1000 * 60;
  const { token = '', tokenExpiration = 0 } =
    appConfig.getCurrentUserToken() || {};

  const timeOffset = MINUTE * 60 * 1.5; // 1.5 hours
  const currentTime = new Date().getTime() + timeOffset;

  if (!token?.length || currentTime >= tokenExpiration) {
    const JWTResponse: B2ChatAPI.Response<{
      token: string;
      expiresAt: string;
    }> = yield call(B2ChatClient.resources.jwt.actions.getJWT);
    if (JWTResponse.data) {
      appConfig.setCurrentUserToken({
        token: JWTResponse.data.token,
        tokenExpiration: new Date(JWTResponse.data.expiresAt).getTime(),
      });
    }
  }
}

function* checkTokenSessionInterval() {
  const MINUTE = 1000 * 60;
  while (true) {
    yield fork(checkTokenSession);
    yield delay(MINUTE * 10);
  }
}

export default function* singletonSession() {
  yield call(checkSession);
  yield fork(checkTokenSessionInterval);

  // push an event to request suspend the rest of the tabs
  yield takeLeading(
    [activateSession, LOGGED_IN_USER_RELOADED],
    suspendSiblingTabs
  );

  // suspend the current tab
  const SuspendTabsChannelEvent =
    createStorageChannelEvent(SUSPEND_NOTIFICATION);
  // const HistoryChannelEvent = createHistoryChannelEvent();
  yield takeLeading(SuspendTabsChannelEvent, suspendCurrentTab);

  // navigate to external site
  yield takeLeading(deactivateSession, deactivateCurrentTab);

  // notify logout to sibling tabs
  yield takeLeading(LOGGING_OUT, logoutSiblingTabs);

  // redirect to login on logging out
  const LoggingOutChannelEvent = createStorageChannelEvent(LOGGING_OUT);
  yield takeLeading(LoggingOutChannelEvent, logoutCurrentTab);
}
