/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
// eslint-disable-next-line import/no-cycle
import type { setTimeLimitsResult } from '@actions/agentconsole';
// FIXME: migrate @model/frontendmodel to TS and convert this require to an import
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import {
  AgentChatTransferStatus,
  EventType,
  MediaType,
  MessageStatus,
  MessagingProvider,
  SortingMode as sortingMode,
} from '@model/frontendmodel';
import { Status } from '@reducers/chatAutoAssignment';
import type { RootState } from '@reducers/rootReducer';

import { TemplateWithFlowContent } from '@src/components/AdminHome/Channels/WhatsApp/WhatsAppFlows/types';
import {
  FormatType,
  TemplateType,
  WhatsappTemplateComponent as WhatsAppComponents,
  WhatsAppTemplateType,
} from '@src/components/AdminHome/Channels/WhatsApp/WhatsAppTemplates/types';
import {
  QuickMessageFlowData,
  QuickMessageMediaFiles,
  QuickMessageType,
} from '@src/components/AdminHome/QuickMessages/types';
import {
  WSCatalogueProduct,
  WSCatalogueShoppingCart,
} from '@src/components/WSCatalogue/types';

import { Effect } from 'redux-saga/effects';
import { Agent } from './agent';
import { ShopifyProductInfo } from './shopify';

export type UnknownObject<T> = { [key: string]: T | unknown };

// eslint-disable-next-line import/no-cycle
export type { RootState } from '@reducers/rootReducer';

export type UUID = string;

export type Provider = keyof typeof MessagingProvider;

export type ChannelStatus = 'ACTIVE' | 'SUSPENDED' | 'MIGRATED';

export type ChatEventDirection = 'INCOMING' | 'OUTGOING';

export type MessageType =
  | 'TEXT'
  | 'IMAGE'
  | 'AUDIO'
  | 'VIDEO'
  | 'FILE'
  | 'LOCATION'
  | 'STICKER'
  | 'TRANSFER'
  | 'AGENT_REQUESTS_RESUME_CHAT'
  | 'INTERACTIVE_LIST'
  | 'PRODUCT_OVERVIEW'
  | 'CHAT_TAKEN'
  | 'CHAT_ASSIGNED'
  | 'TEMPLATE'
  | 'CONTACTS';

export type TimelineChat = {
  id: UUID;
  status?:
    | 'OPENED'
    | 'CLOSED'
    | 'PICKED_UP'
    | 'CLOSED_BY_CONTACT'
    | 'LEFT_BY_CONTACT'
    | 'RESPONDED_BY_AGENT'
    | 'ABANDONED_POLL'
    | 'COMPLETED_POLL';
  assignedTo?: {
    id: number;
    fullName: string;
    avatarUrl: string;
  };
  events: TimelineEvent[];
};

export type TimelineEvent = {
  index: number;
  type: MessageType;
  direction: ChatEventDirection;
  toChatId?: UUID;
  fromAgentUsername?: string;
  toAgentUsername?: string;
  startTemplate?: boolean;
  resumeTemplate?: boolean;
  information?: {
    templateName: string;
    templateId: number;
  };
  agent?: Agent;
  agentWhoAssigned?: Agent;
  agentToAssign?: Agent;
  flowToken?: string;
  content: TemplateWithFlowContent;
  flowCta?: string;
  contacts?: ChatMessageContact[];
} & ChatMessagePayload;

export type ChatMessagePayload = {
  messageId: UUID;
  from: string;
  to: string;
  messagingProvider: string; // TODO Add all providers codes examaple: WHATSAPPBM
  fromAgent?: unknown;
  providerMessageId?: string;
  read: boolean;
  readByContact?: boolean;
  inDelivery?: boolean;
  deliveryAck?: boolean;
  deliveryFailed?: boolean;
  receivedByContact?: boolean;
  context?: unknown;
  quotedMessage?: QuotedMessage;
  isCaptionDefined?: boolean;
  emojiReaction?: string[];
  adPublicity?: ChatMessageAdPayload;
  status?: MessageStatus;
  disabledActions?: boolean;
  information?: ShopifyProductInfo;
  name?: string;
  description?: string;
  price?: string;
  currencySymbol?: string;
  imageUrl?: string;
  available?: string;
  shoppingCart?: WSCatalogueShoppingCart;
  catalogueProduct?: WSCatalogueProduct;
  bodyText?: string;
  footerText?: string;
  headerText?: string;
  buttonLabel?: string;
  rows?: DepartmentList[];
} & ChatMessage;

export type ChatMessage = {
  mediaType?: MediaType;
  text?: string;
  mediaUrl?: string;
  mediaSize?: string;
  latitude?: number;
  longitude?: number;
  address?: string;
  locationName?: string;
  locationUrl?: string;
  sendingTime?: number;
  timestamp?: number;
  deliveryFailed?: boolean;
  isBroadcastWapMsg?: boolean;
  isAutomaticMessage?: boolean;
  messageFromBot?: boolean;
  agentChatButtons?: ChatMessageAgentButtons;
};

export type ChatMessageAgentButtons = {
  buttons: {
    id: string;
    tittle: string;
    active?: boolean;
  }[];
};

export type ChatMessageContact = {
  name: {
    first_name: string;
    last_name: string;
  };
  phones: ChatMessageContactPhone[];
  emails: ChatMessageContactEmail[];
  urls: ChatMessageContactUrl[];
  company: {
    title?: string;
    company: string;
  };
  addresses: ChatMessageContactAddress[];
  birthday: string;
};

export type ChatMessageContactType = 'HOME' | 'UNKNOWN' | 'WORK';

export type ChatMessageContactPhone = {
  type: ChatMessageContactType;
  phone: string;
  wa_id?: string;
};

export type ChatMessageContactEmail = {
  type: ChatMessageContactType;
  email: string;
};

export type ChatMessageContactUrl = {
  type: ChatMessageContactType;
  url: string;
};

export type ChatMessageContactAddress = {
  type: ChatMessageContactType;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
};

export type QuotedMessage = {
  type: MessageType;
  direction: ChatEventDirection;
  messageId: UUID;
  from: string;
  sendingTime: number;
  messagingProvider: Provider;
  providerMessageId: string;
  read: boolean;
  text: string;
  locationName?: string;
  latitude?: number;
  longitude?: number;
  mediaType?: MediaType;
  mediaUrl?: string;
  rows?: DepartmentList[];
  agentChatButtons?: ChatMessageAgentButtons;
  imageUrl?: string;
  contacts?: ChatMessageContact[];
};

export type DepartmentList = {
  id: number;
  title: string;
  description: string;
};

export type GoogleMapLocation = {
  longitude: number;
  latitude: number;
  address?: string;
  locationName?: string;
  // eslint-disable-next-line camelcase
  formatted_address?: string;
  url?: string;
};

export type BotAccount = {
  id: number;
  username: string;
  accessToken?: string;
  botId: number;
  botName?: string;
  provider: MessagingProvider;
  queue?: string | null;
  alias: string;
  webhookUrl?: string;
  iconUrl?: string;
  status: ChannelStatus;
  identifier: string;
  accountType: 'all' | 'BROADCAST_ONLY' | null;
  active: boolean;
  notSuspended: boolean;
  officialWSProvider: boolean;
};

export type BotTemplate = {
  id: number;
  name: string;
  nameSpace: string;
  messageWithPlaceHolders: string;
  byDefault: boolean;
  defaultToResume: boolean;
  createdAt: number;
  status: string;
  botAccountId: number;
  language: null;
  parameters: {
    code: string;
    order: number;
    templatedMsgId: null;
  }[];
};

export type TemplateComponent =
  | {
      type: TemplateType.HEADER;
      format: 'TEXT' | 'IMAGE' | 'DOCUMENT';
      text?: string;
      staticUrl?: string;
    }
  | {
      type: 'BODY';
      text: string;
    }
  | {
      type: TemplateType.FOOTER;
      text: string;
    }
  | {
      type: TemplateType.BUTTONS;
      buttons: {
        text: string;
        type: 'URL' | 'PHONE_NUMBER';
        url?: string;
      }[];
    };

export type TemplateMessage = {
  name: string;
  nameSpace: string;
  messageWithPlaceHolders: string;
  byDefault: boolean;
  defaultToResume: boolean;
  parameters: {
    code: string;
    order: number;
    templatedMsgId?: null | string;
  }[];
  createdAt: number;
  status: 'REJECTED' | 'PENDING' | 'ACTIVE' | 'INACTIVE';
  botAccountId: number;
  language?: string;
  category?: string;
  staticHeaderUrl?: string;
  templateComponent?: TemplateComponent[];
  [field: string]: any;
};

export type CurrentContactChatHistory = {
  messagingAccountId: number;
  closedChats: Chat[];
  closedChatsMeta: {
    firstItemIndex: number;
  };
  scrollToBottom: number;
  chatbotHistory?: null;
  startOfHistory: boolean;
  gotMsgFromContactInPast24h: boolean;
  chatbotHistoryError?: ErrorPayload;
  awaitingRequestId?: null;
};

export type WhatsAppTemplate = {
  id: number;
  botAccountId: number;
  name: string;
  nameSpace: string;
  type: WhatsAppTemplateType;
  category:
    | 'ACCOUNT_UPDATE'
    | 'PAYMENT_UPDATE'
    | 'PERSONAL_FINANCE_UPDATE'
    | 'SHIPPING_UPDATE'
    | 'RESERVATION_UPDATE'
    | 'ISSUE_RESOLUTION'
    | 'APPOINTMENT_UPDATE'
    | 'TRANSPORTATION_UPDATE'
    | 'TICKET_UPDATE'
    | 'ALERT_UPDATE'
    | 'ALY';
  language?: string;
  status: WhatsAppTemplateState;
  byDefault: boolean;
  defaultToResume: boolean;
  parameters: {
    code: string;
    order: number;
    templatedMsgId?: null | string;
  }[];
  createdAt: number;
  staticHeaderUrl?: string;
  messageWithPlaceHolders: string;
  templateComponent: WhatsAppTemplateComponent[] | WhatsAppComponents[];
  components: WhatsAppComponents[];
  templateComponentRecord: Partial<WhatsAppTemplateComponentsRecord>;
  [field: string]: any;
};

export type WhatsAppTemplateState =
  | 'REJECTED'
  | 'PENDING'
  | 'ACTIVE'
  | 'INACTIVE'
  | 'APPROVED';

export type WhatsAppTemplateComponent =
  WhatsAppTemplateComponentsRecord[keyof WhatsAppTemplateComponentsRecord];

export type WhatsAppTemplateComponentsRecord = {
  HEADER: {
    type: TemplateType.HEADER;
    format: FormatType;
    text?: string;
    staticUrl?: string;
    file?: File | string;
  };
  BODY: {
    type: 'BODY';
    text: string;
  };
  FOOTER: {
    type: TemplateType.FOOTER;
    text: string;
  };
  BUTTONS: {
    type: TemplateType.BUTTONS;
    buttons: {
      type: 'URL' | 'PHONE_NUMBER';
      text: string;
      url?: string;
      phoneNumber?: string;
    }[];
  };
};

export type User = {
  authorities: [
    {
      authority: string;
    }
  ];
  enabled: boolean;
  id: number;
  fullName: string;
  email: string;
  status: string;
  active: boolean;
  role: string;
  admin: boolean;
  agent: boolean;
  aggregator: boolean;
  localUsername: string;
  inactive: boolean;
  toActivate: boolean;
  online: boolean;
  departments: {
    departmentId: number;
    botId: number;
    name: string;
    code: string;
  }[];
};

// eslint-disable-next-line no-shadow
export enum DepartmentState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

// eslint-disable-next-line no-shadow
export enum DepartmentType {
  DEPARTMENT = 'DEPARTMENT',
  STANDARD = 'STANDARD',
}

// eslint-disable-next-line no-shadow
export enum ReportsTypeEnum {
  MESSAGE_SENT,
  MESSAGE_DELIVERED_TO_CONTACT,
  MESSAGE_READ_BY_CONTACT,
  MESSAGE_REPLIED_BY_CONTACT,
  FAILED,
}

// eslint-disable-next-line no-shadow
export enum PaymentType {
  TCAUTOMATIC = 'TC-AUTOMATIC',
  TCMANUAL = 'TC-MANUAL',
  OTHER = 'OTHER',
}

// eslint-disable-next-line no-shadow
export enum MerchantStateType {
  TRIAL = 'TRIAL',
  ACTIVE = 'ACTIVE',
  LOSTTRIAL = 'LOSTTRIAL',
  CANCELLATIONREQUEST = 'CANCELLATIONREQUEST',
  SUBSCRIPTIONCANCELED = 'SUBSCRIPTIONCANCELED',
}

// eslint-disable-next-line no-shadow
export enum CauseType {
  PENDING = 'PENDING',
  OK = 'OK',
  REJECTED = 'REJECTED',
  PROCESSING = 'PROCESSING',
  APPROVED = 'APPROVED',
}

// eslint-disable-next-line no-shadow
export enum RoleType {
  ADMIN = 'ADMIN',
  AGENT = 'AGENT',
  MARKETING = 'MARKETING',
  AUDITOR = 'AUDITOR',
}

// eslint-disable-next-line no-shadow
export enum WhatsappTemplateType {
  AUTHENTICATION = 'AUTHENTICATION',
  MARKETING = 'MARKETING',
  UTILITY = 'UTILITY',
}

// eslint-disable-next-line no-shadow
export enum CommandAIEnum {
  TRANSLATE = 'translate',
  CASUAL = 'casual',
  FORMAL = 'formal',
  IMPROVE = 'improve',
  LONGER = 'longer',
  SHORTER = 'shorter',
}

// eslint-disable-next-line no-shadow
export enum TextActionEnum {
  REPLACE = 'REPLACE',
  REPLACE_SEND = 'REPLACESEND',
  DISCARD = 'DISCARD',
}

// eslint-disable-next-line no-shadow
export enum LanguageEnum {
  CHINESE = 'chinese',
  ENGLISH = 'english',
  FRENCH = 'french',
  GERMAN = 'german',
  ITALIAN = 'italian',
  JAPANESE = 'japanese',
  KOREAN = 'korean',
  PORTUGUESE = 'portuguese',
  SPANISH = 'spanish',
}

// eslint-disable-next-line no-shadow
export enum ModeEnum {
  CREATE = 'create',
  DELETE = 'delete',
  LIST = 'list',
}

export type CreateReportPayload = {
  state: null | string;
  userName: string;
  emails: string[];
  campaign: string;
  globalFileUrl: string;
  broadcastId?: string;
  nameReportFile: string | null;
  templateResponsesFor?: string;
};

export type Department = {
  id: number;
  departmentId: number | null;
  botId: number;
  parentName: string;
  parentNodeId: null | number;
  name: string;
  childrens?: Department[];
  state: DepartmentState;
  type: DepartmentType;
  hasFaqsEnabled: boolean;
  number: number; // this property is used to order the Departments[]
  code: string;
  departmentCode: string;
  buttonLabel: string;
  createdAt: number;
  lastUpdatedAt: number;
  bot: { id: number };
  agentIds: number[];
  agents: User[];
  autoChatAssign?: {
    status: Status;
  };
  hiddenToContacts: boolean;
  descriptionText: string | null;
  paddingLeft?: number;
};

export type DepartmentHeadings = {
  businessProcessTreeId: number;
  botAccountId: number;
  headerText: string;
};

export type DepartmentsMessagingAccount = {
  key: number;
  name: string;
  provider: Provider;
};

export type QuickMessage = {
  id: string;
  type: QuickMessageType;
  message?: string;
  location?: GoogleMapLocation;
  flow_data?: QuickMessageFlowData;
  waba_id?: string;
  command: `/${string}`;
  active: boolean;
  createdAtMillis: number;
  files?: QuickMessageMediaFiles;
  tmpFiles?: File[];
  belongsTo: {
    id: number;
    name: string;
  };
  [key: string]: any;
};

export type MessageStatus = keyof typeof MessageStatus;

export type ChatEventTypes = keyof typeof EventType;

export type ChatEventMediaType = keyof typeof MediaType;

export type ChatEventPayload = {
  messageId: string;
  from: string;
  to: string;
  sendingTime: number;
  messagingProvider: Provider;
  text?: string;
  mediaUrl?: string;
  mediaType: ChatEventMediaType;
  mediaSize?: string;
  fromAgent?: string;
  providerMessageId?: string;
  read: boolean;
  readByContact?: string;
  deliveryAck?: string;
  deliveryFailed?: string;
  receivedByContact?: string;
  quotedMessage?: ChatEventQuotedPayload;
  adMessage?: ChatMessageAd;
  locationName?: string;
  latitude?: number;
  longitude?: number;
  address?: string;
  locationUrl?: string;
  status?: MessageStatus;
  timestamp?: number;
  context?: 'STORY';
  toAgent?: {
    fullName: string;
  };
  name?: string;
  description?: string;
  price?: string;
  imageUrl?: string;
  emoji?: string[];
  available?: string;
  information?: ShopifyProductInfo;
};

export type ChatEventQuotedPayload = { chatId: string } & ChatMessagePayload;

export type ChatEvent = {
  type: ChatEventTypes;
  payload: ChatEventPayload;
  createChatWithoutAgents?: string;
  timestamp: number;
  reason?: string;
  agentAvailable?: string;
  isBroadcastWapMsg?: boolean;
  isAutomaticMessage: boolean;
  avoidChatClosingFlow: boolean;
  outgoingMessage: boolean;
  chatClosed: boolean;
  messageEvent: boolean;
  resumeRequestedEvent: boolean;
  firstAgentMsg: boolean;
  incomingMessage: boolean;
  chatClosedByContact: boolean;
  chatLeftByContact: boolean;
  receivedMsg: boolean;
  newChat: boolean;
  chatPickedUp: boolean;
};

export type ChatMessageAdPayload = {
  sourceUrl: string;
  sourceId: string;
  sourceType: 'ad' | 'post';
  headline: string;
  body: string;
  mediaType: 'image' | 'video';
  imageUrl?: string;
  videoUrl?: string;
  thumbnailUrl?: string;
};

export type ChatMessageAd = {
  messageId: string;
  from: string;
  sendingTime: number;
  read: boolean;
  ad: ChatMessageAdPayload;
};

export type StandardMessage = {
  botId: number;
  enabled: boolean;
  standardMessage: {
    id: number;
    code: string;
    value: string;
  };
};

export type ChatStatus = 'PICKED_UP' | 'PICKED_UP';

export type ContactAccount = {
  id: number;
  provider: string;
  accountId: string;
  accountOwner: ContactAccountOwner;
  avatarUrl?: string;
  checked?: string;
};

export type ContactAccountOwner = {
  id: number;
  fullName: string;
  identificationNumber: string | null;
  email?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  country?: string;
  province?: string;
  city?: string;
  cityName: string;
  address?: string;
  company?: string;
  remarks?: string;
  isNewContact: boolean;
  lastUpdate: number;
  createAt: number;
  habeasdata: boolean;
  fullTextHabeasData?: string;
  ipClient?: string;
  channel?: string;
  attributes?: string;
};

export type Chat = {
  id: UUID;
  contactAccountId: string;
  agentId: number;
  queueId?: string;
  botId: string;
  botName: string;
  startTimestamp: number;
  crmTicketId?: string;
  crmProvider?: string;
  viaBotAccount: string;
  viaBotAccountAlias: string;
  events: ChatEvent[];
  assignedTo: Agent;
  status: ChatStatus;
  viaBotAccountId: number;
  viaBotAccountIconUrl?: string;
  statusLog?: string;
  lastModifiedDate?: string;
  chatId?: string;
  outgoing: boolean;
  businessProcessNode?: string;
  openedAt: number;
  pickedUpAt: number;
  respondedAt: number;
  closedAt?: number;
  lastMsgReceivedAt: number;
  lastMsgSentAt: number;
  lastMsgReceivedPrevChatAt?: number;
  chatXferRequest?: ChatXferRequest | null;
  xferredFromId?: string;
  whatsAppOff: boolean;
  hasPassedMaxTime: boolean;
  closeToMaxTime: boolean;
  timeLastMessageReceived: number;
  pickedUp: boolean;
  contactAccount?: ContactAccount;
  lastWAConversationStartedAt?: number;
  waConversationsCounter?: number;
  pinId?: number | null;
  pinned: boolean;
};

export type ChatXferAgent = {
  id: number;
  fullName: string;
  email: string;
  avatarUrl: string;
};

export type ChatXferRequest = {
  chatId: string;
  toChatId?: null;
  status: AgentChatTransferStatus;
  sourceAgent: ChatXferAgent;
  targetAgent: ChatXferAgent;
  targetBizProcessNode: {
    id: string;
    code: string;
    name: string;
  };
  comments: string;
  rejectReason?: null;
  startTimestamp: number;
  pickedUpTimestamp?: null;
  respondedTimestamp?: null;
};

export type MessagingAccount = {
  id: string;
  provider: MessagingProvider;
  accountId: string;
  avatarUrl: null;
  checked: null;
  uid: number;
  contactId: number;
};

export type Authorities = {
  authority: string; // TODO: add all posible values ex: 'ADMIN'
};

export type Employer = {
  id: number;
  name: string;
  country: Country;
  plan: {
    code: 'PRO' | 'BASIC' | 'STANDARD';
    name: string;
    maxUsers: number;
    description: string;
    maxChannels: number;
    status: unknown;
    basic: boolean;
  };
  language: string;
  state: 'ACTIVE' | 'SUSPENDED' | 'TRIAL' | 'LOSTTRIAL';
  startDate: number;
  duration: number;
  city: string;
  auditConstraint: boolean;
  auditExportConstraint: boolean;
  allowContactsExport: boolean;
  zohoUser: null;
  exclusiveLgWapAccounts: number;
  apiCredentials: string;
  botApiBrokerId: string;
  botApiBrokerStatus: number;
  zoneOffset: string;
  expired: boolean;
  zoneIdName: string;
  trialState: boolean;
  activeState: boolean;
  trialRemainingDays: number;
};
export type EnabledConnectorTypes = string; // TODO: add all posible values ex: 'ZENDESK'
export type BusinessProcesses = {
  id: number;
  bot: {
    id: number;
    standardServiceBot: boolean;
    generic: boolean;
  };
  number: number;
  code: string;
  name: string;
  state: string; // TODO: add all posible values ex: 'ACTIVE'
};

export type ChatsOngoingType = {
  [key: string]: {
    chatId: string;
  };
};

export type ChatsIncomingType = ChatsOngoingType;

export type SortingMode = keyof typeof sortingMode;

export type SetTimeLimitsResult = typeof setTimeLimitsResult;

export type ErrorPayload<T extends string = string> = {
  errorCode: T;
  timestamp?: number;
  details: string;
  traceId?: string;
  [key: string]: unknown;
};

export function isErrorPayload<T extends string = string>(
  error?: unknown
): error is ErrorPayload<T> {
  if (error) {
    const keys = Object.keys(error as Record<string, unknown>);
    return keys.includes('errorCode') && keys.includes('details');
  }

  return false;
}

export function isErrorData(error: unknown): error is ErrorData {
  if (error) {
    const keys = Object.keys(error as Record<string, unknown>);
    return keys.includes('status') && keys.includes('message');
  }
  return false;
}

export type Country = {
  isoCode: string;
  name: string;
  languageCode: string;
};

export type MetaCountry = {
  currentCountry: string;
  metaCountry: string;
  countryCode: string;
  metaCountryCode: string;
};

export type WsConversationReportItem = {
  id: number;
  date: number;
  merchant: string;
  type: 'MARKETING' | 'UTILITY' | 'AUTHENTICATION' | 'SERVICES';
  line: string;
  conversationType: 'REGULAR';
  metaCountry: string;
  totalAmount: number;
};

export type WsConversationSummaryReport = {
  total: number;
  marketingTotal: number;
  utilityTotal: number;
  authTotal: number;
  servicesPaidTotal: number;
  servicesFreeTotal: number;
  freeEntryConversation: boolean;
  servicesFreeEntry: number;
};

export type Logout = {
  // Does this state really exist?
  status: unknown; // TODO: all correct type
  success: [];
  failure: {
    response: {
      status: string;
    };
  };
};

export type LoginSuccess = {
  authorities: Authorities[];
  enabled: boolean;
  id: number;
  fullName: string;
  mobileNumber: string;
  email: string;
  avatarUrl: string;
  employer: Employer;
  enabledConnectorTypes: EnabledConnectorTypes[];
  status: string; // TODO: add all posible values ex: 'ACTIVE'
  businessProcesses: BusinessProcesses[];
  active: boolean;
  role: string; // TODO: add all posible values ex: 'ADMIN'
  localUsername: string;
  inactive: boolean;
  toActivate: boolean;
  admin: boolean;
  agent: boolean;
  aggregator: boolean;
  // TODO: JB(timeline-redesign) - check if this property has the right name
  localUserName: boolean;
  mobileAccess?: boolean;
};

export type LoginAuthentication = {
  status?: unknown;
  success: LoginSuccess;
  failure: [];
  isUserReloading: boolean;
};

export type Banner = {
  id: number;
  url: string;
  link?: string;
};

export type ErrorData = {
  error?: string;
  errorCode?: string;
  message: string;
  status: number;
};

export type ErrorResponse = {
  data: null;
  error: ErrorData;
};

export type Pagination<T> = {
  data: T[];
  limit: number;
  total: number;
  offset: number;
};

export type JSONSerializable =
  | { [key: string]: JSONSerializable }
  | number
  | string
  | boolean
  | null
  | JSONSerializable[];

export type DataResponse<D = JSONSerializable> = {
  data: D;
  error: null;
};

export type ClientResponse<D = JSONSerializable> = Promise<
  DataResponse<D> | ErrorResponse
>;

export type WOPromise<T> = T extends Promise<infer U> ? U : never;

export type Response<T> = WOPromise<ClientResponse<T>>;

export type SagaReturn<R = void, Y = unknown> = Generator<Effect, R, Y>;

export type Selector<R, P extends string[] = []> = (
  state: RootState,
  ...args: P
) => R;
