/* eslint-disable no-multi-assign */
// Material UI: Theming
import {
  Box,
  createTheme,
  lighten,
  Paper,
  ThemeProvider as MuiV4ThemeProvider,
  withStyles,
} from '@material-ui/core';
import MuiShadows from '@material-ui/core/styles/shadows';
import { ArrowDropDownRounded } from '@material-ui/icons';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { getPartnersCustomUiConfig } from '@src/selectors/partnersCustomUi';
import { MuiTheme } from 'material-ui/styles';
import baseMuiTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import * as muiColors from 'material-ui/styles/colors';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { fade } from 'material-ui/utils/colorManipulator';
import { useSelector } from 'react-redux';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    chatItem: {
      color: string;
      activeBgColor: string;
      hover: string;
    };
  }

  interface ThemeOptions {
    chatItem: {
      color: string;
      activeBgColor: string;
      hover: string;
    };
  }
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    b2chatblue: {
      '50': string;
      '100': string;
      '200': string;
    };
    b2chatgray: {
      '50': string;
      '100': string;
      '400': string;
      '500': string;
      '600': string;
      '700': string;
      '800': string;
      '900': string;
    };
    b2chatyellow: {
      '300': string;
      '500': string;
    };
    b2chatgreen: {
      '50': string;
      '500': string;
      '600': string;
    };
    b2chatred: {
      '50': string;
      '100': string;
      '500': string;
    };
    b2chatorange: {
      '100': string;
      '500': string;
      '600': string;
    };
  }
  interface ThemeOptions {
    b2chatblue: {
      '50': string;
      '100': string;
      '200': string;
    };
    b2chatgray: {
      '50': string;
      '100': string;
      '400': string;
      '500': string;
      '600': string;
      '700': string;
      '800': string;
      '900': string;
    };
    b2chatyellow: {
      '300': string;
      '500': string;
    };
    b2chatgreen: {
      '50': string;
      '500': string;
      '600': string;
    };
    b2chatred: {
      '50': string;
      '100': string;
      '500': string;
    };
    b2chatorange: {
      '100': string;
      '500': string;
      '600': string;
    };
  }
}

// Define an object with customized values for the MUI theme fields. Use the source code to see the values that
// can be overriden: https://github.com/callemall/material-ui/blob/master/src/styles/getMuiTheme.js,
// ./node_modules/material-ui/styles/baseThemes/lightBaseTheme.js
const customMuiThemeValues = {
  fontFamily: 'Poppins, sans-serif',
  fontSize: 14,
  appBar: {
    color: muiColors.black,
  },
  raisedButton: {
    color: '#2196F3',
    textColor: muiColors.white,
    disabledColor: fade(muiColors.grey500, 0.2),
    disabledTextColor: fade(muiColors.grey600, 0.2),
  },
  flatButton: {
    color: muiColors.transparent,
    buttonFilterColor: '#999999',
    disabledTextColor: fade(muiColors.grey600, 0.2),
    textColor: muiColors.blue500,
    primaryTextColor: muiColors.blue500,
  },
  badge: {
    primaryColor: muiColors.red500,
    primaryTextColor: muiColors.white,
    secondaryColor: muiColors.red500,
    secondaryTextColor: muiColors.white,
  },
  textField: {
    focusColor: muiColors.blue500,
  },
  palette: {
    primary1Color: muiColors.white,
    primary2Color: muiColors.grey600,
    alternateTextColor: muiColors.black,
    accent1Color: muiColors.blue500,
  },
  stepper: {
    backgroundColor: 'transparent',
    hoverBackgroundColor: fade(muiColors.black, 0.06),
    iconColor: muiColors.blue500,
    hoveredIconColor: muiColors.grey700,
    inactiveIconColor: muiColors.grey500,
    textColor: fade(muiColors.black, 0.87),
    disabledTextColor: fade(muiColors.black, 0.26),
    connectorLineColor: muiColors.grey400,
  },
  checkbox: {
    boxColor: muiColors.grey600,
    checkedColor: '#b7b7cf',
    labelColor: '#b7b7cf',
  },
  radioButton: {
    checkedColor: muiColors.grey600,
    borderColor: muiColors.grey600,
    labelColor: muiColors.grey600,
  },
  toggle: {
    trackOnColor: '#2196F3',
  },
};

// Build a custom MUI theme from an existing base theme.
// Base theme values are overriden by providing a second argument. The resulting custom theme will be
// deeply merged with the second argument.
const customMuiTheme = getMuiTheme(
  baseMuiTheme,
  customMuiThemeValues as unknown as MuiTheme
);

export const primary = {
  main: '#7C69FF',
  contrastText: '#FFFFFF',
  light: '#6f5bff',
};

const text = {
  primary: '#323444',
  disabled: '#A1A1AA',
};

const ripple = {
  borderRadius: 1000,
  backgroundColor: primary.main,
};

const grey = {
  '50': '#FAFAFA',
  '100': '#F4F4F5',
  '200': '#E4E4E7',
  '300': '#D4D4D8',
  '400': '#A1A1AA',
  '500': '#71717A',
  '600': '#52525B',
  '700': '#3F3F46',
  '800': '#27272A',
  '900': '#18181b',
} as const;

const b2chatblue = {
  '50': '#7C69FF',
  '100': '#E9EAED',
  '200': '#ECE9FF',
} as const;

const b2chatgray = {
  '50': '#E5E5E5',
  '100': '#F4F3F6',
  '400': '#DBDDE1',
  '500': '#747881',
  '600': '#B4B7BB',
  '700': '#5F6368',
  '800': '#4E4E4E',
  '900': '#121F24',
};

const b2chatyellow = {
  '300': '#FEF2C4',
  '500': '#E0B300',
};

const b2chatgreen = {
  '50': '#E1F2DE',
  '500': '#25C16F',
  '600': '#27E381',
};

const b2chatred = {
  '50': '#FFF0EE',
  '100': '#FA4C56',
  '500': '#F27468',
};

const b2chatorange = {
  '100': '#FDE5C6',
  '500': '#D97D54',
  '600': '#F8981D',
};
const chatitem = {
  color: '#fafafa',
  activeBgColor: '#efedff',
  hover: '#e4e4e7',
};

type Shadows = typeof MuiShadows &
  Record<'xxs' | 'xs' | 's' | 'm' | 'l', string>;

const shadows = [...MuiShadows] as Shadows;
shadows.xxs = shadows[1] = '0px 4px 4px rgba(0, 0, 0, 0.05)';
shadows.xs = shadows[2] = '0px 8px 8px 0px #00000014';
shadows.s = shadows[3] = '0px 8px 16px rgba(0, 0, 0, 0.16)';
shadows.m = shadows[4] = '0px 16px 24px rgba(0, 0, 0, 0.24)';
shadows.l = shadows[5] = '0px 16px 32px rgba(0, 0, 0, 0.32)';

export const PaperRounded = withStyles({
  root: {
    border: `1px solid ${grey[300]}`,
    borderRadius: 8,
  },
})(Paper);

export const CustomMuiV4ThemeProvider: React.FC = ({ children }) => {
  const partnersCustomUi = useSelector(getPartnersCustomUiConfig);

  const primaryColorConfig = {
    ...primary,
    light: partnersCustomUi?.primaryColor
      ? lighten(partnersCustomUi?.primaryColor, 0.15)
      : primary.light,
    main: partnersCustomUi?.primaryColor || primary.main,
  };

  const chatItemColorConfig = {
    ...chatitem,
    // TODO: This value should be retrieved from backend custom-ui settings
    activeBgColor: partnersCustomUi?.primaryColor
      ? '#f9ffed'
      : chatitem.activeBgColor,
  };

  const theme = createTheme({
    typography: {
      htmlFontSize: 14,
      fontFamily: 'Poppins, sans-serif',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 600,
      h1: {
        fontSize: '2.75rem',
        fontWeight: 500,
        lineHeight: 1.28,
      },
      h2: {
        fontSize: '1.85rem',
        fontWeight: 500,
        lineHeight: 1.2,
      },
      h3: {
        fontSize: '1.375rem',
        fontWeight: 500,
        lineHeight: 1.24,
      },
      h4: {
        fontSize: '1.125rem',
        fontWeight: 500,
        lineHeight: 1.32,
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 400,
      },
      subtitle1: {
        fontSize: '0.875rem',
      },
      subtitle2: {
        fontSize: '0.75rem',
      },
      button: {
        fontWeight: 400,
      },
    },
    chatItem: chatItemColorConfig,
    b2chatblue,
    b2chatgray,
    b2chatyellow,
    b2chatgreen,
    b2chatred,
    b2chatorange,
    palette: {
      primary: primaryColorConfig,

      text: {
        ...text,
      },
      grey,
      action: {
        active: grey[600],
      },
    },

    shadows,
    overrides: {
      MuiInputLabel: {
        root: {
          color: text.primary,
          zIndex: 1,
          cursor: 'text',
          paddingLeft: 8,
        },
      },
      MuiSelect: {
        icon: {
          color: grey[400],
        },
      },
      MuiFormLabel: {
        root: {
          color: text.primary,
          marginBottom: 6,
          display: 'flex',
          flexFlow: 'row nowrap',
          flex: '1 1 100%',
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: -3,
        },
        label: {
          marginLeft: 8,
        },
      },
      MuiFormControl: {
        root: {
          '& .MuiInputLabel-root, & .MuiInputLabel-outlined.MuiInputLabel-marginDense':
            {
              padding: '0 4px',
              top: 0,
              left: 4,
              transform: 'translate(0, 12px)',
              borderRadius: 4,
            },
          '& .MuiInputLabel-shrink[data-shrink="true"]': {
            transform: 'translate(12px, -5px) scale(0.80)',
          },
        },
        marginDense: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      MuiInputBase: {
        root: {
          '& legend': {
            marginLeft: 7,
            fontSize: '0.78rem',
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          border: 0,
          borderRadius: 8,
          background: 'white',
          '&:hover': {
            border: 0,
          },
          '&$focused': {
            border: 0,
          },
        },
        focused: {},
        input: {
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 8,
        },
        adornedEnd: {
          paddingRight: 8,
        },
        adornedStart: {
          paddingLeft: 8,
        },
        notchedOutline: {
          border: `1px solid ${grey[300]}`,
          borderWidth: '1px !important',
        },
      },
      MuiTouchRipple: {
        root: {
          opacity: 0.2,
        },
        ripple,
        ripplePulsate: ripple,
        rippleVisible: ripple,
        child: ripple,
        childPulsate: ripple,
        childLeaving: ripple,
      },
      MuiPopover: {
        root: {},
        paper: {
          border: 0,
          margin: 0,
          borderRadius: 8,
          boxShadow: '0px 16px 24px 0px #0000003D',
        },
      },
      MuiList: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      MuiListItem: {
        gutters: {
          paddingLeft: 12,
          paddingRight: 12,
        },
        button: {
          borderRadius: 8,
          '&:hover': {
            backgroundColor: primaryColorConfig.main,
            color: primary.contrastText,
          },
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: '0',
          marginRight: 10,
        },
      },
      MuiMenuItem: {
        root: {
          borderRadius: 8,
          '&:hover': {
            color: primary.contrastText,
          },
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: 8,
        },
      },
      MuiDialogTitle: {
        root: {
          fontSize: 18,
          padding: '20px 30px 0px',
          '& > *': { fontSize: 'inherit' },
        },
      },
      MuiDialogContent: {
        root: {
          lineHeight: 1.4,
          padding: '20px 30px',
          '& > *': { lineHeight: 'inherit' },
        },
      },
      MuiDialogActions: {
        root: { padding: '0px 30px 20px 30px' },
      },
      MuiButton: {
        root: {
          borderRadius: 8,
          paddingTop: 6,
          paddingBottom: 6,
          boxSizing: 'border-box',
          textTransform: 'none',
        },
        label: {
          textTransform: 'none',
          '&:first-letter': {
            textTransform: 'uppercase',
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: primaryColorConfig.main,
            opacity: 0.6,
          },
          '&:active': {
            backgroundColor: primaryColorConfig.main,
            opacity: 0.85,
          },
          '&$disabled': {
            backgroundColor: '#D4D2D5',
          },
        },
        outlined: {
          paddingTop: 6,
          paddingBottom: 6,
          background: 'white',
          '&:hover': {
            backgroundColor: primaryColorConfig.main,
            opacity: 0.6,
          },
          '&:active': {
            backgroundColor: primaryColorConfig.main,
            opacity: 0.85,
          },
          '&$disabled': {
            backgroundColor: '#D4D2D5',
          },
        },
        sizeSmall: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
      MuiIconButton: {
        root: {
          color: text.primary,
        },
        colorPrimary: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
      MuiChip: {
        root: {
          borderRadius: 2,
          padding: '6px 2px',
          backgroundColor: '#F4F4F5',
          fontWeight: 300,
          fontSize: '0.9rem',
        },
        sizeSmall: {
          padding: '2px 4px',
        },
        deleteIconSmall: {
          marginRight: 0,
        },
      },
      MuiTooltip: {
        tooltip: {
          boxShadow: 'unset',
          filter: 'drop-shadow(-2px 2px 10px #7070754c)',
          backgroundColor: 'white',
          color: text.primary,
          padding: '8px 12px',
          borderRadius: 8,
          fontSize: '0.9rem',
          fontWeight: 400,
          maxHeight: 400,
          maxWidth: 600,
        },
        arrow: {
          color: 'white',
        },
      },
      MuiTypography: {
        colorPrimary: {
          color: `${primaryColorConfig.main} !important`,
        },
      },
      MuiSwitch: {
        root: {
          padding: 0,
          width: 46,
          height: 26,
          margin: 4,
          borderRadius: 20,
          background: 0,
          overflow: 'visible',
          '& .MuiTouchRipple-root': {
            margin: -8,
            opacity: 0.3,
          },
        },
        track: {
          borderRadius: 20,
          backgroundColor: '#D3D3D7',
        },
        thumb: {
          width: 22,
          height: 22,
          margin: 2,
          backgroundColor: 'white',
        },
        switchBase: {
          padding: 0,
          '&$checked': {
            '& + $thumb': {
              backgroundColor: 'white',
            },
            '& + $track': {
              opacity: 1,
              background: 'white',
            },
          },
        },
      },
      MuiSlider: {
        root: {
          height: 8,
          padding: '2px 0',
        },
        rail: {
          height: 8,
          borderRadius: 4,
          backgroundColor: grey[300],
          opacity: 1,
        },
        track: {
          height: 8,
          borderRadius: 4,
          transition: 'all 0.1s',
        },
        thumb: {
          marginTop: -6,
          marginBottom: -6,
          marginLeft: -10,
          width: 20,
          height: 20,
          boxShadow: shadows.m,
          backgroundColor: primaryColorConfig.main,
          border: '5px solid white',
          boxSizing: 'border-box',
          transition: 'all 0.1s',
        },
        valueLabel: {
          top: 30,
          '& > *': {
            backgroundColor: 'white',
            transform: 'rotate(0deg)',
            borderRadius: 4,
            boxShadow: shadows.xxs,
            width: 28,
            height: 22,
            '& > *': {
              color: 'black',
              transform: 'rotate(0deg)',
              fontSmooth: 'always',
            },
          },
        },
      },
      MuiFormHelperText: {
        root: {
          textAlign: 'right',
        },
        contained: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
      MuiLinearProgress: {
        root: {
          borderRadius: 2,
        },
        buffer: {
          borderRadius: 2,
        },
        bar: {
          borderRadius: 2,
          backgroundColor: grey[300],
        },
      },
      MuiBreadcrumbs: {
        separator: {
          color: text.primary,
        },
        li: {
          color: text.primary,
        },
      },
      MuiLink: {
        root: {
          '&:hover': {
            cursor: 'pointer',
            color: primaryColorConfig.main,
          },
        },
      },
      MuiAvatar: {
        root: {
          color: primaryColorConfig.main,
        },
      },
      ...{
        MuiAlert: {
          root: {
            padding: '4px 12px',
            fontSize: '0.95rem',
          },
          filledWarning: {
            backgroundColor: '#fcb252',
          },
        },
        MuiAutocomplete: {
          paper: {
            boxShadow: shadows.xs,
            border: `1px solid ${primaryColorConfig.main}`,
            borderRadius: 8,
            margin: '2px 0',
            minWidth: 250,
          },
          listbox: {
            padding: 0,
          },
          option: {
            borderRadius: 0,
            '&[aria-selected="true"], &:active': {
              backgroundColor: 'rgb(210, 205, 255)',
              color: primaryColorConfig.main,
            },
            '&:active': {
              color: primaryColorConfig.main,
            },
          },
          groupLabel: {
            color: grey[800],
            fontWeight: 400,
            cursor: 'default',
            borderTop: `1px solid ${grey[200]}`,
            padding: '0px 8px',
            lineHeight: '2.8rem',
            textAlign: 'bottom',
          },
        },
      },
      MuiPaper: {
        outlined: {
          border: `1px solid ${grey[300]}`,
        },
      },
    },

    props: {
      MuiButtonBase: {},
      MuiButton: {
        disableElevation: true,
      },
      MuiChip: {
        deleteIcon: <CloseRoundedIcon />,
      },
      MuiInputBase: {
        margin: 'dense',
      },
      MuiInputLabel: {
        margin: 'dense',
      },
      MuiInput: {
        margin: 'dense',
      },
      MuiOutlinedInput: {
        margin: 'dense',
      },
      MuiTextField: {
        margin: 'dense',
        variant: 'outlined',
        InputProps: {
          margin: 'dense',
        },
        InputLabelProps: {
          margin: 'dense',
        },
      },
      MuiSelect: {
        variant: 'outlined',
        margin: 'none',
        IconComponent: ArrowDropDownRounded,
      },
      MuiSwitch: {
        color: 'primary',
      },
      MuiTooltip: {
        arrow: true,
      },
      MuiCheckbox: {
        color: 'primary',
        checkedIcon: (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="white"
            boxShadow="0 0 0 1px currentColor"
            borderRadius={4}
            width={18}
            height={18}
          >
            <svg
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.39644 7.5821L0.14644 4.3321C-0.0488135 4.13684 -0.0488135 3.82026 0.14644 3.62499L0.85353 2.91788C1.04878 2.72261 1.36539 2.72261 1.56064 2.91788L3.74999 5.10721L8.43935 0.417879C8.6346 0.222625 8.95121 0.222625 9.14646 0.417879L9.85355 1.12499C10.0488 1.32024 10.0488 1.63682 9.85355 1.8321L4.10355 7.58212C3.90828 7.77737 3.59169 7.77737 3.39644 7.5821Z"
                fill="currentColor"
              />
            </svg>
          </Box>
        ),
        icon: (
          <Box
            bgcolor="white"
            boxShadow={`0 0 0 1px ${grey[400]}`}
            borderRadius={4}
            width={18}
            height={18}
          />
        ),
      },
    },
  });

  return <MuiV4ThemeProvider theme={theme}>{children}</MuiV4ThemeProvider>;
};

export default customMuiTheme;
