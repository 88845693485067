import { B2ChatLogoDesktop } from '@src/resources';
// eslint-disable-next-line import/no-cycle
import { RootState } from '@types';
import { getPartnersCustomUiConfig } from './partnersCustomUi';

export const preferencesSelect = (state: RootState) =>
  state.generalProperties?.preferences;

export const consolePreferencesSelect = (state: RootState) =>
  state.generalProperties?.preferences?.agentConsoleConfig;

export const showChatWithoutDeptToAllAgentsPreferencesSelect = (
  state: RootState
) => {
  const showChatWithoutDeptToAllAgentss =
    state.generalProperties?.preferences?.agentConsoleConfig
      ?.showChatWithoutDeptToAllAgents;

  return showChatWithoutDeptToAllAgentss ?? true;
};

export const defaultStandardBotIdSelector = (state: RootState) =>
  state.generalProperties?.defaultStandardBotId;

export const getB2ChatLogo = (state: RootState) => {
  const preferences = preferencesSelect(state);
  const partnersCustomUi = getPartnersCustomUiConfig(state);
  const uiConfig = preferences.uiConfigurationProperties;
  const b2chatLogo =
    partnersCustomUi?.logoUrl ||
    uiConfig?.desktopLightLogo ||
    B2ChatLogoDesktop;

  return b2chatLogo;
};
