import { ErrorData } from '@b2chat/chat-center-sdk';
import B2ChatClient from '@client-sdk';
import {
  enqueueQuickMessageFiles,
  fetchQuickMessageList,
  fetchQuickMessageListFailure,
  fetchQuickMessageListFulfill,
  fetchQuickMessageListSuccess,
  QueueQuickMessageFile,
  removeQuickMessageItem,
  removeQuickMessageItemFailure,
  removeQuickMessageItemFulfill,
  removeQuickMessageItemSuccess,
  saveQuickMessageItem,
  saveQuickMessageItemFailure,
  saveQuickMessageItemFulfill,
  saveQuickMessageItemSuccess,
  sendQuickMessageWithFiles,
  sendQuickMessageWithFilesFulfill,
  sendQuickMessageWithFilesSuccess,
  uploadFilesQuickMessage,
  uploadFilesQuickMessageFailure,
  uploadFilesQuickMessageFulfill,
  uploadFilesQuickMessageSuccess,
} from '@reducers/quickMessages';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import {
  OUT_MESSAGE_DELIVERY_CONFIRMED,
  OUT_MESSAGE_DELIVERY_FAILED,
} from '@src/actions/agentconsole';
import { QuickMessageMediaFiles } from '@src/components/AdminHome/QuickMessages/types';
import { MediaType } from '@src/model/frontendmodel';
import {
  MessageState,
  queueChatMessage,
  selectNextMessageStateToSent,
} from '@src/reducers/chatMessageQueue';
import { activeChatSelector } from '@src/selectors/chats';
import { ChatTray } from '@src/types/chats';
import env from '@src/utils/env';
import { QuickMessage } from '@types';
import { B2ChatAPI } from '@types-api';
import { call, put, select, take, takeLeading } from 'redux-saga/effects';

export default function* quickMessageSaga() {
  // eslint-disable-next-line func-names
  yield takeLeading(fetchQuickMessageList, function* (action) {
    const { botId, filters } = action.payload;
    try {
      const response: B2ChatAPI.Response<QuickMessage[]> = botId
        ? yield call(
            B2ChatClient.resources.quickMessage.actions
              .retrieveQuickMessageList,
            { data: filters, params: { botId } }
          )
        : yield call(
            B2ChatClient.resources.quickMessage.actions
              .retrieveQuickMessageListAny,
            { data: filters }
          );

      if (response.data) {
        yield put(fetchQuickMessageListSuccess(response.data));
      } else {
        throw response.error;
      }
    } catch (error) {
      yield put(fetchQuickMessageListFailure(error as ErrorData));
    } finally {
      yield put(fetchQuickMessageListFulfill());
    }
  });

  // eslint-disable-next-line func-names
  yield takeLeading(removeQuickMessageItem, function* (action) {
    const id = action.payload;
    try {
      const response: B2ChatAPI.Response<QuickMessage[]> = yield call(
        B2ChatClient.resources.quickMessage.actions.removeQuickMessageItem,
        { params: { id } }
      );

      if (!response.error) {
        yield put(removeQuickMessageItemSuccess(id));
      } else {
        throw response.error;
      }
    } catch (error) {
      yield put(
        removeQuickMessageItemFailure({ error: error as ErrorData, id })
      );
    } finally {
      yield put(removeQuickMessageItemFulfill());
    }
  });

  function* uploadSingleFileQuickMessageSaga(action: { payload: File }) {
    const formData = new FormData();
    const file = action.payload;

    formData.append('file', file);

    try {
      const response: B2ChatAPI.Response<B2ChatAPI.Files.UploadFiles> =
        yield call(B2ChatClient.resources.files.actions.uploadFiles, {
          params: { prefix: `${env.APP_ENV}/console`, audio: 'false' },
          data: formData,
        });

      return response;
    } catch (error) {
      return error;
    }
  }

  // eslint-disable-next-line func-names
  yield takeLeading(uploadFilesQuickMessage, function* (action) {
    const newFiles = action.payload.filter(file => file.type);
    if (newFiles.length === 0) {
      yield put(uploadFilesQuickMessageFulfill());
      return;
    }

    try {
      const results: B2ChatAPI.Response<B2ChatAPI.Files.UploadFiles>[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const file of newFiles) {
        const result: B2ChatAPI.Response<B2ChatAPI.Files.UploadFiles> =
          yield call(uploadSingleFileQuickMessageSaga, { payload: file });
        results.push(result);
      }

      const hasErrors = results.some(result => result.error);

      if (hasErrors) {
        yield put(
          uploadFilesQuickMessageFailure(hasErrors as unknown as ErrorData)
        );
      } else {
        const data = results
          .map(item => (item?.data ? item.data[0] : null))
          .filter(Boolean) as unknown as {
          url: string;
          name: string;
          mime: string;
        }[];
        yield put(uploadFilesQuickMessageSuccess(data));
      }
    } catch (error) {
      yield put(uploadFilesQuickMessageFailure(error as ErrorData));
    } finally {
      yield put(uploadFilesQuickMessageFulfill());
    }
  });

  // eslint-disable-next-line func-names
  yield takeLeading(saveQuickMessageItem, function* (action) {
    const { botId, quickMessage } = action.payload;

    yield put(uploadFilesQuickMessage(quickMessage.tmpFiles || []));

    const resp: ActionCreatorWithPayload<unknown> = yield take([
      uploadFilesQuickMessageFulfill,
      uploadFilesQuickMessageFailure,
    ]);

    if (resp.type === uploadFilesQuickMessageFailure.type) {
      throw new Error('Error uploading quick message files');
    }

    const files: QuickMessageMediaFiles = yield select(
      state => state.quickMessages.files
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tmpFiles, ...restQuickMessage } = quickMessage;

    try {
      const response: B2ChatAPI.Response<QuickMessage> = yield call(
        B2ChatClient.resources.quickMessage.actions.save,
        {
          params: { botId },
          data: {
            ...restQuickMessage,
            files: files.fileDetails.length > 0 ? files : null,
          },
        }
      );

      if (!response.error) {
        yield put(saveQuickMessageItemSuccess(response.data));
      } else {
        throw response.error;
      }
    } catch (error) {
      yield put(saveQuickMessageItemFailure(error as ErrorData));
    } finally {
      yield put(saveQuickMessageItemFulfill());
    }
  });

  // eslint-disable-next-line func-names
  yield takeLeading(sendQuickMessageWithFiles, function* (action) {
    const activeChat: ChatTray = yield select(activeChatSelector);
    const files: QueueQuickMessageFile[] = yield select(
      state => state.quickMessages.queueFiles
    );

    yield put(enqueueQuickMessageFiles([]));

    // eslint-disable-next-line no-restricted-syntax
    for (const f of files) {
      const { type, file, url } = f;
      yield put(
        queueChatMessage(
          {
            type,
            file,
            url,
            onlyUrl: true,
          },
          activeChat?.id,
          activeChat?.provider
        )
      );

      const nextMessage: MessageState | undefined = yield select(
        selectNextMessageStateToSent(activeChat.id, 'standard')
      );

      const resp: {
        type: string;
        chatId: string;
        messageId: string;
      } = yield take([
        OUT_MESSAGE_DELIVERY_CONFIRMED,
        OUT_MESSAGE_DELIVERY_FAILED,
      ]);

      if (
        resp.messageId !== nextMessage?.id ||
        resp.type === OUT_MESSAGE_DELIVERY_FAILED
      ) {
        // eslint-disable-next-line no-console
        console.error('Error sending quick message file: ', file);
      }
    }
    yield put(sendQuickMessageWithFilesSuccess());
    yield put(sendQuickMessageWithFilesFulfill());
    if (action.payload.length > 0) {
      yield put(
        queueChatMessage(
          {
            type: MediaType.TEXT,
            text: action.payload,
          },
          activeChat?.id,
          activeChat?.provider
        )
      );
    }
  });
}
