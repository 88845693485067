import { StickersKeyName } from '@src/components/CustomEmojiPicker/constants';
import env from '../utils/env';

const keepKeysOnLogout = [
  'userid',
  'activeChat',
  'agentChatComposeTextRows',
  StickersKeyName,
];

const isAStickerKey = key => key.startsWith('blob:');

//todo set reCaptchaSiteKey according to environment
let reCaptchaSiteKey = '6LfJBEYUAAAAAKUINO1mqXyS0eiI5lGp_Qu__fnJ';

try {
  if (process.env.REACT_APP_ENV === 'local') {
    reCaptchaSiteKey = '6LcuhGcUAAAAABO93bnw5O6C-0VXb7ok3vE0ePTV';
  }
} catch (_) {
  // Ignore errors attempting to set key for local environment
}

export const appConfig = {
  webServicesBaseUri: env.WEB_SERVICE_BASE_URI,
  reCaptchaSiteKey,
  getData(key) {
    return JSON.parse(localStorage.getItem(key));
  },
  setData(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  },
  getCurrentUser() {
    return this.getData('loginAuthentication');
  },
  setCurrentUser(user) {
    this.setData('loginAuthentication', user);
  },
  getCurrentUserToken() {
    return this.getData('loginAuthenticationToken');
  },
  setCurrentUserToken(data) {
    this.setData('loginAuthenticationToken', data);
  },
  deleteStoreData() {
    sessionStorage.removeItem('inputText');
    Object.keys(localStorage).map(key => {
      if (!keepKeysOnLogout.includes(key) && !isAStickerKey(key)) {
        localStorage.removeItem(key);
      }
      return key;
    });
  },
  characterLength: 2000,
  reasonCharacterLength: 200,
  queryCount: 100000,
  maxWidthConsole: 1400,
};

export default appConfig;
