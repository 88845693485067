import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { ErrorData } from '@types';

type Loading = 'fetch-carousel-images';

export type CarouselImages = {
  srcImage: string;
  principalText: string;
  secondaryText: string;
}[];

export type CarouselImagesState = {
  error: ErrorData | null;
  loading: Loading[];
  images: CarouselImages | [];
};

type CarouselImagesReducer<P = void> = (
  state: CarouselImagesState,
  action: PayloadAction<P>
) => void;

const initialState: CarouselImagesState = {
  error: null,
  loading: [],
  images: [],
};

const handleCarouselImages: CarouselImagesReducer<{
  isFromAmerica: boolean;
}> = state => {
  state.loading.push('fetch-carousel-images');
  state.error = null;
};

const handleCarouselImagesSuccess: CarouselImagesReducer<CarouselImages> = (
  state,
  action
) => {
  state.images = action.payload || [];
};

const handleCarouselImagesFailure: CarouselImagesReducer<ErrorData> = (
  state,
  action
) => {
  state.error = action.payload;
};

const handleCarouselImagesFulfill: CarouselImagesReducer = state => {
  state.loading = state.loading.filter(l => l !== 'fetch-carousel-images');
};

const carouselImagesSlice = createSlice({
  name: 'CarouselImages',
  initialState,
  reducers: {
    fetchCarouselImages: handleCarouselImages,
    fetchCarouselImagesSuccess: handleCarouselImagesSuccess,
    fetchCarouselImagesFailure: handleCarouselImagesFailure,
    fetchCarouselImagesFulfill: handleCarouselImagesFulfill,
  },
});

const { actions, reducer } = carouselImagesSlice;

export const {
  fetchCarouselImages,
  fetchCarouselImagesSuccess,
  fetchCarouselImagesFailure,
  fetchCarouselImagesFulfill,
} = actions;

export default reducer;
