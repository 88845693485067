import { createApi } from '@reduxjs/toolkit/dist/query/react';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';

type SearchConversationTagsRequest = {
  botId: string | number;
  pageNum: number;
  pageSize: number;
  filter: {
    searchFilter: string;
    stateFilter?: null | string;
  };
  sorting?: {
    column: 'tag_name';
    direction: 'ASC';
  }[];
};

export type ConversationTag = {
  tagName: string;
  botId: number;
  color: string;
  emoji: string;
  active?: boolean;
};

type SearchConversationTagsResponse = {
  total: number;
  list: ConversationTag[];
  pageNum: number;
  pageSize: number;
  size: number;
  startRow: number;
  endRow: number;
  pages: number;
  prePage: number;
  nextPage: number;
  isFirstPage: boolean;
  isLastPage: boolean;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  navigatePages: number;
  navigatepageNums: number[];
  navigateFirstPage: number;
  navigateLastPage: number;
};

export const TagsApi = createApi({
  reducerPath: 'tagsApi',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['conversation-tags'],
  endpoints: builder => ({
    searchConversationTags: builder.query<
      SearchConversationTagsResponse,
      SearchConversationTagsRequest
    >({
      providesTags: ['conversation-tags'],
      serializeQueryArgs: ({ endpointName, queryArgs }) => ({
        endpointName,
        botId: queryArgs.botId,
        queryArgs: queryArgs.filter?.searchFilter || '',
      }),
      keepUnusedDataFor: 0,
      merge(currentCacheData, responseData) {
        if (!currentCacheData) {
          return responseData;
        }
        const list = currentCacheData.list.slice();
        list.splice(
          (responseData.pageNum - 1) * responseData.pageSize,
          responseData.pageSize,
          ...responseData.list
        );
        return {
          ...currentCacheData,
          ...responseData,
          list,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.pageNum !== previousArg?.pageNum;
      },
      query: request =>
        apiResource(B2ChatClient.resources.newTagsAdmin.actions.search, {
          params: {
            botId: `${request.botId}`,
            pageNum: `${request.pageNum}`,
            pageSize: `${request.pageSize}`,
          },
          data: {
            filter: {
              searchFilter: request.filter.searchFilter,
              stateFilter: request.filter.stateFilter || null,
            },
            sorting: request.sorting || [
              {
                column: 'tag_name',
                direction: 'ASC',
              },
            ],
          },
        }),
    }),
  }),
});

export const {
  useSearchConversationTagsQuery,
  useLazySearchConversationTagsQuery,
  util: tagsApiUtil,
} = TagsApi;
