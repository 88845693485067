/* eslint-disable @typescript-eslint/ban-types */
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';
import { AndOrOption } from '@src/components/AdminHome/ChatsManagement/DialogMassiveAssignment/LogicalSelectors/AndOr';
import { ContainOption } from '@src/components/AdminHome/ChatsManagement/DialogMassiveAssignment/LogicalSelectors/Contains';

export type SummarizeChatResponse = { content: string };

export type SummarizeChatRequest = { chatId: string };

export type ReassignmentRequest = {
  assignedTo: number;
  assignToList: number[];
  dateType: 'LATEST_CONTACT_MESSAGE' | 'CREATION_DATE' | (string & {});
  from: string;
  to: string;
  departmentId?: number | null;
  tagFilter?: {
    operator: string;
    secondOperator: AndOrOption;
    tags: string[];
  };
};

export type ReassignmentResponse = {
  status: string;
  message: string;
};

export type ReassignmentTotalRequest = {
  dateType: 'LATEST_CONTACT_MESSAGE' | 'CREATION_DATE' | (string & {});
  from: string;
  to: string;
  assignedTo: number;
  departmentId?: number | null;
  tagFilter?: {
    operator: ContainOption;
    secondOperator: AndOrOption;
    tags: string[];
  };
};

export type ReassignmentTotalResponse = {
  totalChats: number;
};

export const chatServicesApi = createApi({
  reducerPath: 'chatServices',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['summarize-chat', 'reassignment'],
  endpoints: ({ query, mutation }) => ({
    summarizeChat: query<SummarizeChatResponse, SummarizeChatRequest>({
      providesTags: ['summarize-chat'],
      query: request =>
        apiResource(B2ChatClient.resources.servicesChats.actions.summarize, {
          params: request,
        }),
    }),
    reassignment: mutation<ReassignmentResponse, ReassignmentRequest>({
      invalidatesTags: ['reassignment'],
      query: request =>
        apiResource(B2ChatClient.resources.chats.actions.reassignment, {
          data: request,
        }),
    }),
    reassignmentTotal: query<
      ReassignmentTotalResponse,
      ReassignmentTotalRequest
    >({
      providesTags: ['reassignment'],
      query: request =>
        apiResource(B2ChatClient.resources.chats.actions.reassignmentTotal, {
          data: request,
        }),
    }),
  }),
});

export const {
  useLazySummarizeChatQuery,
  useLazyReassignmentTotalQuery,
  useReassignmentTotalQuery,
  useReassignmentMutation,
} = chatServicesApi;
