import { createApi } from '@reduxjs/toolkit/query';
import B2ChatClient, { apiResource, b2chatBaseQuery } from '@src/client';

export type GetChatsRequest = {
  type: 'ALL';
  merchantId: number;
  agent: number;
  businessProcess: number | null;
  contact: string;
  tags: string[];
  contactTagFilter: string[];
  dateRange: {
    to: string;
    from: string;
    sortingMode: 'CREATION_DATE';
  };
  sorting: [];
  limit: number;
  offset: number;
};

export type GetChatsResponse = {
  data: {
    id: string;
    provider: string;
    contactId: string;
    contact: string;
    agent: string;
    businessProcess: string;
    account: string;
    accountIconUrl: null | string;
    bot: string;
    botAccount: string;
    contactMsgAccount: string;
    status: string;
    duration: string;
    tags: null | string[];
    openDate: number;
    creationDate: number;
  }[];
  paging: {
    hasMoreItems: true;
  };
};
export type GetChatsCountResponse = {
  count: number;
};

const chatsApi = createApi({
  reducerPath: 'chatsApi',
  baseQuery: b2chatBaseQuery,
  tagTypes: ['chats'],
  endpoints: ({ query }) => ({
    getChats: query<GetChatsResponse, GetChatsRequest>({
      providesTags: ['chats'],
      serializeQueryArgs: args => args,
      keepUnusedDataFor: 0,
      query: request =>
        apiResource(B2ChatClient.resources.chats.actions.getChats, {
          data: request,
        }),
    }),
    getChatsCount: query<GetChatsCountResponse, GetChatsRequest>({
      providesTags: ['chats'],
      keepUnusedDataFor: 0,
      query: request => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { offset, limit, ...data } = request;
        return apiResource(B2ChatClient.resources.chats.actions.getChatsCount, {
          data,
        });
      },
    }),
  }),
});

export default chatsApi;
