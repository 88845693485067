import { assistantActions } from '@src/reducers/assistants';
import { assistantFilesQueueSelector } from '@src/selectors/assistants';
import {
  AssistantFile,
  AssistantForm,
  AssistantStatus,
  AssistantTable,
  fetchAssistantApi,
} from '@src/types/assistant';
import env from '@src/utils/env';
import { call, delay, fork, put, select, takeEvery } from 'redux-saga/effects';

function* fetchAssistantsTableSaga({
  payload,
}: ReturnType<typeof assistantActions.fetchAssistantsTable>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const queryParams = new URLSearchParams(payload).toString();
    const response: {
      data: AssistantTable[];
      paging: { hasMore: boolean };
    } = yield call(
      fetchAssistantApi,
      `${env.WEB_SERVICE_ASSISTANTS_BASE_URI}/assistants?${queryParams}`,
      { method: 'GET' }
    );

    yield put(
      assistantActions.setFetchAssistantsTableRequest({
        hasMoreAssistants: response?.paging?.hasMore || false,
        assistants: response.data,
        status: 'success',
      })
    );
  } catch (error) {
    yield put(
      assistantActions.setFetchAssistantsTableRequest({ status: 'failure' })
    );
  } finally {
    yield put(
      assistantActions.setFetchAssistantsTableRequest({ status: 'idle' })
    );
  }
}

function* fetchAssistantSaga({
  payload: assistantId,
}: ReturnType<typeof assistantActions.fetchAssistant>) {
  try {
    const assistant: AssistantForm = yield call(
      fetchAssistantApi,
      `${env.WEB_SERVICE_ASSISTANTS_BASE_URI}/assistants/${assistantId}`,
      { method: 'GET' }
    );

    yield put(
      assistantActions.setFetchAssistantRequest({
        assistant,
        status: 'success',
      })
    );
  } catch (error) {
    yield put(assistantActions.setFetchAssistantRequest({ status: 'failure' }));
  } finally {
    yield put(assistantActions.setFetchAssistantRequest({ status: 'idle' }));
  }
}

function* createAssistantsSaga({
  payload,
}: ReturnType<typeof assistantActions.createAssistant>) {
  try {
    const assistantFilesQueue: File[] = yield select(
      assistantFilesQueueSelector
    );

    const newAssistant: {
      id: string;
    } = yield call(
      fetchAssistantApi,
      `${env.WEB_SERVICE_ASSISTANTS_BASE_URI}/assistants`,
      { method: 'POST', body: JSON.stringify(payload) }
    );

    if (newAssistant.id) {
      const assistantStore: { id: string } = yield call(
        fetchAssistantApi,
        `${env.WEB_SERVICE_ASSISTANTS_BASE_URI}/vector-stores/${newAssistant.id}`,
        { method: 'POST' }
      );

      if (assistantFilesQueue?.length > 0 && assistantStore?.id) {
        assistantActions.setUpdateAssistantRequest({ status: 'pending' });
        yield fork(uploadFileAssistantSaga, {
          payload: {
            assistantStoreId: assistantStore.id,
            assistantFiles: assistantFilesQueue,
          },
        } as ReturnType<typeof assistantActions.uploadAssistantFile>);
      }
      yield put(
        assistantActions.setCreateAssistantRequest({
          assistant: { id: newAssistant.id, ...payload },
          assistantStoreId: assistantStore.id,
          status: 'success',
        })
      );
    }
  } catch (error) {
    yield put(
      assistantActions.setCreateAssistantRequest({ status: 'failure' })
    );
  } finally {
    yield delay(500);
    yield put(assistantActions.setCreateAssistantRequest({ status: 'idle' }));
  }
}

function* updateAssistantsSaga({
  payload: { assistantId, assistant, assistantStatusIsChanged = false },
}: ReturnType<typeof assistantActions.updateAssistant>) {
  let hasErrorUpdate = false;
  const baseUrl = `${env.WEB_SERVICE_ASSISTANTS_BASE_URI}/assistants/${assistantId}`;
  try {
    const assistantUpdated: {
      id: string;
    } = yield call(fetchAssistantApi, baseUrl, {
      method: 'PUT',
      body: JSON.stringify({
        ...assistant,
        status: assistantStatusIsChanged
          ? AssistantStatus.INACTIVE
          : assistant.status,
      }),
    });

    yield put(
      assistantActions.setUpdateAssistantRequest({
        assistant: { ...assistant, id: assistantUpdated.id },
        status: 'success',
      })
    );
  } catch (error) {
    hasErrorUpdate = true;
    yield put(
      assistantActions.setUpdateAssistantRequest({ status: 'failure' })
    );
  } finally {
    yield put(assistantActions.setUpdateAssistantRequest({ status: 'idle' }));
  }

  if (assistantStatusIsChanged && !hasErrorUpdate) {
    yield call(fetchAssistantApi, baseUrl, {
      method: 'PATCH',
      body: JSON.stringify({ status: assistant.status }),
    });
  }
}

function* fetchAssistantStoreSaga({
  payload: assistantId,
}: ReturnType<typeof assistantActions.fetchAssistantStore>) {
  try {
    const assistantStore: {
      id: string;
    } = yield call(
      fetchAssistantApi,
      `${env.WEB_SERVICE_ASSISTANTS_BASE_URI}/assistants/${assistantId}/vector-store`,
      { method: 'GET' }
    );

    yield put(
      assistantActions.setFetchAssistantStoreRequest({
        assistantId,
        assistantStoreId: assistantStore.id,
        status: 'success',
      })
    );
  } catch (error) {
    yield put(
      assistantActions.setFetchAssistantStoreRequest({ status: 'failure' })
    );
  } finally {
    yield put(
      assistantActions.setFetchAssistantStoreRequest({ status: 'idle' })
    );
  }
}

function* fetchAssistantFilesSaga({
  payload: { assistantId, assistantStoreId },
}: ReturnType<typeof assistantActions.fetchAssistantFiles>) {
  try {
    const assistantFiles: AssistantFile[] = yield call(
      fetchAssistantApi,
      `${env.WEB_SERVICE_ASSISTANTS_BASE_URI}/vector-stores/${assistantStoreId}/files`,
      { method: 'GET' }
    );

    yield put(
      assistantActions.setFetchAssistantFilesRequest({
        status: 'success',
        assistantFiles,
        assistantId,
      })
    );
  } catch (error) {
    yield put(
      assistantActions.setFetchAssistantFilesRequest({
        assistantId,
        status: 'failure',
      })
    );
  }
}

function* uploadFileAssistantSaga({
  payload: { assistantStoreId, assistantFiles },
}: ReturnType<typeof assistantActions.uploadAssistantFile>) {
  try {
    if (!assistantStoreId) return;
    // eslint-disable-next-line no-restricted-syntax
    for (const assistantFile of assistantFiles) {
      const formData = new FormData();
      formData.append('file', assistantFile, assistantFile.name);

      yield call(
        fetchAssistantApi,
        `${env.WEB_SERVICE_ASSISTANTS_BASE_URI}/vector-stores/${assistantStoreId}/files`,
        {
          method: 'POST',
          body: formData,
        },
        true
      );
    }

    yield put(
      assistantActions.setUploadAssistantFileRequest({
        assistantStoreId,
        status: 'success',
      })
    );
  } catch (error) {
    yield put(
      assistantActions.setUploadAssistantFileRequest({
        assistantStoreId,
        status: 'failure',
      })
    );
  } finally {
    yield put(
      assistantActions.setUploadAssistantFileRequest({
        assistantStoreId,
        status: 'idle',
      })
    );
  }
}

function* deleteAssistantFileSaga({
  payload: { assistantId, assistantStoreId, assistantFileId },
}: ReturnType<typeof assistantActions.deleteAssistantFile>) {
  try {
    yield call(
      fetchAssistantApi,
      `${env.WEB_SERVICE_ASSISTANTS_BASE_URI}/vector-stores/${assistantStoreId}/files/${assistantFileId}`,
      { method: 'DELETE' }
    );

    yield put(
      assistantActions.setDeleteAssistantFileRequest({
        assistantId,
        assistantFileId,
        status: 'success',
      })
    );
  } catch (error) {
    yield put(
      assistantActions.setDeleteAssistantFileRequest({
        assistantId,
        status: 'failure',
      })
    );
  } finally {
    yield put(
      assistantActions.setDeleteAssistantFileRequest({
        assistantId,
        status: 'idle',
      })
    );
  }
}

export default function* assistantSaga() {
  yield takeEvery(
    assistantActions.fetchAssistantsTable,
    fetchAssistantsTableSaga
  );

  yield takeEvery(assistantActions.fetchAssistant, fetchAssistantSaga);
  yield takeEvery(assistantActions.createAssistant, createAssistantsSaga);
  yield takeEvery(assistantActions.updateAssistant, updateAssistantsSaga);

  yield takeEvery(
    assistantActions.fetchAssistantStore,
    fetchAssistantStoreSaga
  );

  yield takeEvery(
    assistantActions.fetchAssistantFiles,
    fetchAssistantFilesSaga
  );

  yield takeEvery(
    assistantActions.uploadAssistantFile,
    uploadFileAssistantSaga
  );

  yield takeEvery(
    assistantActions.deleteAssistantFile,
    deleteAssistantFileSaga
  );
}
