/* eslint-disable @typescript-eslint/no-empty-function */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import {
  AssistantFile,
  AssistantForm,
  AssistantTable,
  AssistantTableFilters,
} from '@src/types/assistant';

type Loading = 'idle' | 'pending' | 'success' | 'failure' | 'fulfill';

export type AssistantState = {
  assistantsTable: AssistantTable[];
  assistantsTableHasMore: boolean;

  assistantsConfig?: AssistantForm;
  assistantFilesQueue?: File[];

  assistantFiles: {
    [assistantId: string]: {
      storeId?: string;
      files: AssistantFile[];
      status: Loading;
    };
  };

  fetchAssistantsTableStatus: Loading;
  assistantStatus: Loading;
};

type AssistantReducer<P = void> = (
  state: AssistantState,
  action: PayloadAction<P>
) => void;

const initialState: AssistantState = {
  assistantsTable: [],
  assistantsTableHasMore: true,

  assistantsConfig: undefined,

  assistantFiles: {},
  assistantFilesQueue: [],

  fetchAssistantsTableStatus: 'idle',
  assistantStatus: 'idle',
  // assistantFilesStatus: 'idle',
};

const fetchAssistantsTable: AssistantReducer<AssistantTableFilters> = () => ({
  ...initialState,
  fetchAssistantsTableStatus: 'pending',
});

const setFetchAssistantsTableRequest: AssistantReducer<{
  assistants?: AssistantTable[];
  hasMoreAssistants?: boolean;
  status: Loading;
}> = (state, { payload: { assistants, hasMoreAssistants, status } }) => {
  state.fetchAssistantsTableStatus = status;
  if (typeof assistants !== 'undefined') state.assistantsTable = assistants;
  if (typeof hasMoreAssistants !== 'undefined')
    state.assistantsTableHasMore = hasMoreAssistants;
};

const fetchAssistant: AssistantReducer<string> = () => ({
  ...initialState,
  assistantStatus: 'pending',
});
// state = Object.assign(state, initialState, { assistantStatus: 'pending' });

const setFetchAssistantRequest: AssistantReducer<{
  assistant?: AssistantForm;
  status: Loading;
}> = (state, { payload: { assistant, status } }) => {
  state.assistantStatus = status;
  if (typeof assistant !== 'undefined') state.assistantsConfig = assistant;
};

const createAssistant: AssistantReducer<AssistantForm> = (
  state,
  { payload: assistant }
) => {
  state.assistantStatus = 'pending';
  if (assistant?.id) {
    state.assistantFiles[assistant.id] = {
      files: [],
      status: 'pending',
    };
  }
};
const setCreateAssistantRequest: AssistantReducer<{
  assistant?: AssistantForm;
  assistantStoreId?: string;
  status: Loading;
}> = (state, { payload: { assistant, assistantStoreId, status } }) => {
  state.assistantStatus = status;
  if (assistant?.id) {
    state.assistantsConfig = assistant;
    if (typeof assistantStoreId !== 'undefined') {
      state.assistantFiles[assistant.id] = {
        storeId: assistantStoreId,
        files: [],
        status,
      };
    }
  }
};

const updateAssistant: AssistantReducer<{
  assistantId: string;
  assistant: AssistantForm;
  assistantStatusIsChanged?: boolean;
}> = state => {
  state.assistantStatus = 'pending';
};

const setUpdateAssistantRequest: AssistantReducer<{
  assistant?: AssistantForm;
  status: Loading;
}> = (state, { payload: { assistant, status } }) => {
  state.assistantStatus = status;
  if (typeof assistant !== 'undefined') state.assistantsConfig = assistant;
};

const fetchAssistantStore: AssistantReducer<string> = () => {};

const setFetchAssistantStoreRequest: AssistantReducer<{
  status: Loading;
  assistantId?: string;
  assistantStoreId?: string;
}> = (state, { payload: { assistantStoreId, assistantId } }) => {
  if (assistantStoreId && assistantId)
    state.assistantFiles[assistantId] = {
      ...(state.assistantFiles[assistantId] || {}),
      storeId: assistantStoreId,
    };
};

const fetchAssistantFiles: AssistantReducer<{
  assistantId: string;
  assistantStoreId: string;
}> = (state, { payload: { assistantId, assistantStoreId } }) => {
  // state.assistantFilesStatus = 'pending';
  if (assistantId) {
    state.assistantFiles[assistantId] = {
      status: 'pending',
      storeId: assistantStoreId,
      files: state.assistantFiles[assistantId]?.files || [],
    };
  }
};

const setFetchAssistantFilesRequest: AssistantReducer<{
  status: Loading;
  assistantId: string;
  assistantFiles?: AssistantFile[];
}> = (state, { payload: { assistantId, assistantFiles = [], status } }) => {
  // state.assistantFilesStatus = status;
  state.assistantFiles[assistantId] = {
    ...(state.assistantFiles[assistantId] || {}),
    files: assistantFiles,
    status,
  };
};

const uploadAssistantFile: AssistantReducer<{
  assistantStoreId?: string;
  assistantFiles: File[];
}> = (state, { payload }) => {
  state.assistantFilesQueue = [
    ...(state.assistantFilesQueue || []),
    ...payload.assistantFiles,
  ];

  if (payload?.assistantStoreId) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [assistantId, assistantFiles] of Object.entries(
      state.assistantFiles
    )) {
      if (assistantFiles.storeId === payload.assistantStoreId)
        state.assistantFiles[assistantId].status = 'pending';
    }
  }
  // state.assistantFilesStatus = 'pending';
};

const setUploadAssistantFileRequest: AssistantReducer<{
  assistantStoreId?: string;
  status: Loading;
}> = (state, { payload: { assistantStoreId, status } }) => {
  if (assistantStoreId) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [assistantId, assistantFiles] of Object.entries(
      state.assistantFiles
    )) {
      if (assistantFiles.storeId === assistantStoreId)
        state.assistantFiles[assistantId].status = status;
    }
  }

  if (status === 'success') state.assistantFilesQueue = [];
  // state.assistantFilesStatus = status;
};

const deleteAssistantFile: AssistantReducer<{
  assistantId: string;
  assistantStoreId: string;
  assistantFileId: string;
}> = (state, { payload: { assistantId } }) => {
  // state.assistantFilesStatus = 'pending';
  if (assistantId) state.assistantFiles[assistantId].status = 'pending';
};

const setDeleteAssistantFileRequest: AssistantReducer<{
  status: Loading;
  assistantId: string;
  assistantFileId?: string;
}> = (state, { payload: { status, assistantId, assistantFileId } }) => {
  // state.assistantFilesStatus = status;
  if (status === 'success' && state.assistantFiles[assistantId]) {
    state.assistantFiles[assistantId].files = state.assistantFiles[
      assistantId
    ].files?.filter(({ id }) => id !== assistantFileId);
  } else {
    state.assistantFiles[assistantId].status = status;
  }
};

const assistantsSlice = createSlice({
  name: 'Assistants',
  initialState,
  reducers: {
    fetchAssistantsTable,
    setFetchAssistantsTableRequest,

    fetchAssistant,
    setFetchAssistantRequest,

    createAssistant,
    setCreateAssistantRequest,

    updateAssistant,
    setUpdateAssistantRequest,

    fetchAssistantStore,
    setFetchAssistantStoreRequest,

    fetchAssistantFiles,
    setFetchAssistantFilesRequest,

    uploadAssistantFile,
    setUploadAssistantFileRequest,

    deleteAssistantFile,
    setDeleteAssistantFileRequest,
  },
});

const { actions, reducer } = assistantsSlice;

export const assistantActions = actions;

export default reducer;
