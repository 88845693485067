/* eslint-disable no-shadow */
import { loadedImagesErrors } from '@actions/common';
import B2ChatClient from '@client-sdk';
import { Chat, QuotedMessage, RootState } from '@types';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  actionChannel,
  call,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from '@redux-saga/core/effects';
// eslint-disable-next-line import/no-unresolved
import { Channel, Task } from '@redux-saga/types';
import {
  OUT_MESSAGE_RECEIPT,
  sendMessageFail,
  sendMessageOk,
  startSendingMessage,
} from '@src/actions/agentconsole';
import { MediaType, Message, MessageStatus } from '@src/model/frontendmodel';
import {
  attachFile,
  attachFileFailure,
  attachFileFulfill,
  attachFileSuccess,
  cancelChatMessage,
  chatMessageSent,
  clearMessageQueue,
  FileErrorType,
  FileSizeExceeded,
  isFileMediaMessage,
  MessageState,
  MessageStateAction,
  queueChatMessage,
  selectMessageState,
  selectNextMessageStateToSent,
  sendAttachmentQueue,
} from '@src/reducers/chatMessageQueue';
import { removeQuotedMessage } from '@src/reducers/chats';
import { selectRoot } from '@src/selectors';
import {
  activeChatSelector,
  activeQuotedMessageSelector,
  chatByIdSelector,
  quotedMessageByChatIdSelector,
} from '@src/selectors/chats';
import { selectAuthenticationInfo } from '@src/selectors/user';
import { B2ChatAPI } from '@src/types/api';
import { ChatTray } from '@src/types/chats';
import env from '@src/utils/env';
import { Action, AnyAction } from 'redux';
import { waitForWebSocketReadyState } from './websocket';

function prepareMessage(state: RootState, messageState: MessageState) {
  const { chatId, message } = messageState;

  const userId = selectAuthenticationInfo(state)?.id;
  const activeChat = chatByIdSelector(chatId)(state) as ChatTray;
  const activeQuotedMessage = quotedMessageByChatIdSelector(chatId)(state);

  if (!activeChat?.contact || !userId)
    throw new Error('Fail to transform Message');

  let messageText;
  switch (message.type) {
    case MediaType.TEXT:
      messageText = message.text;
      break;

    case MediaType.IMAGE:
    case MediaType.VIDEO:
      messageText = message.caption ? message.caption : '';
      break;

    case MediaType.FILE:
      messageText = (message?.file?.name as string) ?? '';
      break;

    default:
      messageText = '';
      break;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let m: Record<string, any> = new Message(
    messageState.id,
    messageState.chatId,
    userId,
    activeChat?.contact?.id,
    messageText,
    activeChat?.provider
  );

  if (isFileMediaMessage(message)) {
    m.status = MessageStatus.TRANSMITTING_MEDIA;
    m.mediaUrl = message.url;
    m.mediaType = message.type;
  } else if (
    message.type === MediaType.LOCATION ||
    message.type === MediaType.PRODUCT_OVERVIEW ||
    message.type === MediaType.CATALOG ||
    message.type === MediaType.CART ||
    message.type === MediaType.ORDER ||
    message.type === MediaType.FLOW
  ) {
    m = {
      ...m,
      ...message,
      mediaType: message.type,
    };
    delete m.type;
  }

  if (activeQuotedMessage) m.quotedMessage = activeQuotedMessage;

  return m;
}

export type MessagePayload =
  | {
      id?: string;
      type: 'TEXT';
      text: string;
      quotedMessageId?: string;
    }
  | {
      id?: string;
      type: 'MEDIA';
      mediaType:
        | MediaType.FILE
        | MediaType.IMAGE
        | MediaType.AUDIO
        | MediaType.VIDEO;
      fileUrl: string;
      quotedMessageId?: string;
    }
  | {
      id?: string;
      type: 'LOCATION';
      longitude: number;
      latitude: number;
      locationName: string;
      address: string;
      quotedMessageId?: string;
    };

function* sendMessage(messageState: MessageState) {
  const { id, chatId, message } = messageState;
  const data: MessagePayload = {
    id,
    ...(isFileMediaMessage(message) && {
      type: 'MEDIA',
      mediaType: message.type,
      fileUrl: message.url,
      ...(message?.caption && { caption: message.caption }),
    }),
    ...(message.type === MediaType.TEXT && message),
    ...(message.type === MediaType.LOCATION && message),
    ...(message.type === MediaType.PRODUCT_OVERVIEW && message),
    ...(message.type === MediaType.CATALOG && message),
    ...(message.type === MediaType.CART && message),
    ...(message.type === MediaType.ORDER && message),
    ...(message.type === MediaType.FLOW && message),
    ...(!!message?.quotedMessageId && {
      quotedMessageId: message?.quotedMessageId,
    }),
  } as MessagePayload;

  // eslint-disable-next-line no-useless-catch
  try {
    const response: B2ChatAPI.Response<void> = yield call(
      B2ChatClient.resources.sentMessage.actions.message,
      {
        params: { agentChatId: chatId },
        data,
      }
    );

    if (response.error) {
      throw response.error;
    } else {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    throw e;
  }
}

function* uploadFileSaga(action: MessageStateAction) {
  const { id, loading, message, error } = action.payload;

  if (error as FileErrorType) {
    yield put(loadedImagesErrors(error));
    return;
  }

  if (loading.includes('cancelled')) return;

  if (!isFileMediaMessage(message) || message.onlyUrl) return;

  yield call(waitForWebSocketReadyState, WebSocket.OPEN);

  try {
    const formData = new FormData();
    formData.append('file', message.file);
    const isAudio = message?.type === MediaType.AUDIO;

    const controller = new AbortController();

    const {
      response,
      cancel,
    }: {
      response: B2ChatAPI.Response<B2ChatAPI.Files.UploadFiles>;
      cancel: MessageStateAction;
    } = yield race({
      response: call(B2ChatClient.resources.files.actions.uploadFiles, {
        data: formData,
        signal: controller.signal,
        params: { prefix: `${env.APP_ENV}/console`, audio: `${isAudio}` },
      }),
      cancel: take(
        (action: AnyAction) =>
          cancelChatMessage.match(action) && action.payload.id === id
      ),
    });

    if (cancel) {
      controller.abort();
      return;
    }

    if (response.data) {
      yield put(attachFileSuccess({ id, data: response.data[0] }));
    } else {
      throw response.error;
    }
  } catch (error) {
    yield put(attachFileFailure({ id, error }));
  } finally {
    yield put(attachFileFulfill({ id }));
  }
}

function* startSendingMessageSaga(action: MessageStateAction) {
  const messageState = action.payload;

  const { chatId, error } = messageState;

  if (error as FileSizeExceeded) return;

  const rootState: RootState = yield select(selectRoot);
  const message = prepareMessage(rootState, messageState);
  yield put(startSendingMessage(chatId, message));
}

function* sendAllMessagesSaga(chatId: string, queue: MessageState['queue']) {
  while (true) {
    yield call(waitForWebSocketReadyState, WebSocket.OPEN);

    let messageState: MessageState | undefined = yield select(
      selectNextMessageStateToSent(chatId, queue)
    );

    if (!messageState) break;

    const { id, loading } = messageState;
    const activeChat: Chat = yield select(activeChatSelector);
    const activeQuotedMessage: QuotedMessage = yield select(
      activeQuotedMessageSelector
    );

    if (queue === 'attachments') {
      const rootState: RootState = yield select(selectRoot);
      const message = prepareMessage(rootState, messageState);
      yield put(startSendingMessage(chatId, message));
    }

    if (loading.includes('uploading-file')) {
      yield take(
        (action: Action<unknown>) =>
          attachFileFulfill.match(action) && action.payload.id === id
      );
    }

    messageState = yield select(selectMessageState(id));
    if (activeQuotedMessage && messageState?.message) {
      messageState.message.quotedMessageId = activeQuotedMessage?.messageId;
      yield put(removeQuotedMessage(activeChat.id));
    }

    if (messageState) {
      const { id, loading } = messageState;

      // eslint-disable-next-line no-continue
      if (loading.includes('upload-file-failure')) continue;
      // eslint-disable-next-line no-continue
      if (loading.includes('cancelled')) continue;

      const rootState: RootState = yield select(selectRoot);
      const payloadMsg = prepareMessage(rootState, messageState);

      try {
        yield call(sendMessage, messageState);
        yield put(chatMessageSent({ id }));
        yield put(sendMessageOk(payloadMsg));
        if (isFileMediaMessage(messageState.message)) {
          yield race([
            take(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (action: Record<string, any>) =>
                action.type === OUT_MESSAGE_RECEIPT && action.messageId === id
            ),
            delay(2000),
          ]);
        } else {
          yield delay(500);
        }
      } catch (e) {
        yield put(cancelChatMessage({ id, reason: 'failed' }));
        yield put(sendMessageFail(payloadMsg, (e as Error).message));
      }
    }
  }
  yield put(clearMessageQueue({ chatId }));
}

function* sendStandardQueue() {
  const chann: Channel<MessageStateAction> = yield actionChannel(
    queueChatMessage
  );

  const tasks: Record<string, Task> = {};

  while (true) {
    const {
      payload: { chatId },
    }: MessageStateAction = yield take(chann);

    const isRunning = tasks[chatId]?.isRunning() || false;

    if (!isRunning) {
      tasks[chatId] = yield fork(sendAllMessagesSaga, chatId, 'standard');
    }
  }
}

export default function* chatMessageQueueSaga() {
  yield takeEvery([attachFile, queueChatMessage], uploadFileSaga);
  yield takeEvery(queueChatMessage, startSendingMessageSaga);
  yield fork(sendStandardQueue);
  yield takeLatest(sendAttachmentQueue, action =>
    sendAllMessagesSaga(action.payload.chatId, 'attachments')
  );
}
