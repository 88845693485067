import B2ChatClient from '@client-sdk';
import {
  checkPossibleUrl,
  fetchUrl,
  fetchUrlFailure,
  fetchUrlFulfill,
  fetchUrlSuccess,
  setShowUrlPreview,
} from '@reducers/urlPreview';
import { isValidUrl } from '@src/utils/validation';
import { ErrorData } from '@types';
import { B2ChatAPI } from '@types-api';

import { buffers, TakeableChannel } from 'redux-saga';
import {
  actionChannel,
  call,
  debounce,
  put,
  takeEvery,
} from 'redux-saga/effects';

function* fetchUrlSaga(action: ReturnType<typeof fetchUrl>) {
  const { url } = action.payload;

  try {
    const response = (yield call(
      [B2ChatClient.resources.urlPreview.actions, 'getMetadata'],
      {
        params: {
          url,
        },
      }
    )) as B2ChatAPI.Response<B2ChatAPI.UrlPreview.UrlData>;

    if (response.error != null) {
      yield put(fetchUrlFailure(response.error));
    } else {
      yield put(fetchUrlSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchUrlFailure(error as ErrorData));
  } finally {
    yield put(fetchUrlFulfill());
  }
}

function* checkPossibleUrlSaga(action: ReturnType<typeof checkPossibleUrl>) {
  const possibleURL = action.payload?.trim().split(' ')[0];
  const isURL = isValidUrl(possibleURL);

  if (isURL) {
    yield put(fetchUrl({ url: possibleURL }));
  }
  yield put(setShowUrlPreview(isURL));
}

function* channels() {
  const checkPossibleUrlChan: TakeableChannel<
    ReturnType<typeof checkPossibleUrl>
  > = yield actionChannel(checkPossibleUrl, buffers.sliding(1));
  yield debounce(1000, checkPossibleUrlChan, checkPossibleUrlSaga);
}

function* UrlPreviewSaga() {
  yield takeEvery(fetchUrl, fetchUrlSaga);
  yield channels();
}

export default UrlPreviewSaga;
