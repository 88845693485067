import B2ChatClient from '@client-sdk';
import {
  ButtonType,
  FormatType,
  QuickReplyMessageType,
  TemplateType,
  WhatsAppTemplateMode,
  WhatsappTemplateComponent,
} from '@src/components/AdminHome/Channels/WhatsApp/WhatsAppTemplates/types';
import {
  saveWhatsAppTemplate,
  saveWhatsAppTemplateFailure,
  saveWhatsAppTemplateFulfill,
  SaveWhatsAppTemplateState,
  saveWhatsAppTemplateSuccess,
} from '@src/reducers/saveWhatsAppTemplate';
import { ResponseFile } from '@src/reducers/uploadFiles';
import {
  WhatsAppTemplatesState,
  fetchWhatsAppTemplatesByBotAccount,
  fetchWhatsAppTemplatesByBotAccountFailure,
  fetchWhatsAppTemplatesByBotAccountFulfill,
  fetchWhatsAppTemplatesByBotAccountSuccess,
  fetchWhatsAppTemplatesByContactId,
  fetchWhatsAppTemplatesByContactIdFailure,
  fetchWhatsAppTemplatesByContactIdFulfill,
  fetchWhatsAppTemplatesByContactIdSuccess,
  updateWhatsAppTemplateByBotAccountStatus,
} from '@src/reducers/whatsAppTemplates';
import { activeChatSelector } from '@src/selectors/chats';
import { selectWhatsAppTemplates } from '@src/selectors/whatsAppTemplates';
import { Pagination, WhatsAppTemplate, isErrorPayload } from '@src/types';
import { B2ChatAPI } from '@src/types/api';
import { ChatTray } from '@src/types/chats';
import { omit, snakeCase } from 'lodash';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import uploadFileSaga from './uploadFileSaga';

function* fetchWhatsAppTemplatesByBotAccountSaga() {
  const { filter }: WhatsAppTemplatesState = yield select(
    selectWhatsAppTemplates
  );

  if (!filter.botAccountId) return;

  try {
    const response: B2ChatAPI.Response<Pagination<WhatsAppTemplate>> =
      yield call(
        B2ChatClient.resources.templates.actions.getTemplatesByBotAccount,
        {
          params: {
            ...omit(filter, ['total']),
            columnOrder: snakeCase(filter.columnOrder),
            states: filter.states.join(','),
          },
        }
      );

    if (response.error) throw response.error;

    yield put(
      fetchWhatsAppTemplatesByBotAccountSuccess({
        total: response.data.total,
        data: response.data.data,
      })
    );
  } catch (error) {
    if (isErrorPayload(error)) {
      yield put(fetchWhatsAppTemplatesByBotAccountFailure({ error }));
    } else {
      yield put(
        fetchWhatsAppTemplatesByBotAccountFailure({
          error: {
            errorCode: 'OPERATION_FAILED',
            details: (error as Error).message,
            timestamp: Date.now(),
            traceId: '0',
          },
        })
      );
    }
  } finally {
    yield put(fetchWhatsAppTemplatesByBotAccountFulfill());
  }
}

type FileToUpload = {
  file: File;
  componentIndex: number;
  buttonIndex: number;
  messageIndex: number;
};

function* saveWhatsAppTemplateSaga({
  payload,
}: ReturnType<typeof saveWhatsAppTemplate>) {
  const { accountId, formValues } = payload;
  const listFilesToUpload: FileToUpload[] = [];

  const { mode, components, headerComponent, footerComponent } = formValues;

  let header = components?.find(item => item.type === TemplateType.HEADER);
  const footer = components?.find(item => item.type === TemplateType.FOOTER);
  const btnIndex = components?.findIndex(
    item => item.type === TemplateType.BUTTONS
  );
  let template = {};

  try {
    if (mode === WhatsAppTemplateMode.CREATE) {
      components.forEach((item, componentIndex) => {
        if (item.type === TemplateType.BUTTONS) {
          item.buttons?.forEach((btn, buttonIndex) => {
            delete btn.id;
            btn.text = btn.text?.trim();

            if (btn.type === ButtonType.URL) {
              delete btn.uriType;
            }

            if (btn.type === ButtonType.PHONE_NUMBER && btn.phoneNumber) {
              btn.phoneNumber = btn.phoneNumber.replace(/\s+/g, '');
            }

            if (
              btn.type === ButtonType.QUICK_REPLY &&
              btn.quickReplyButtonSettings
            ) {
              const { action, redirection } = btn.quickReplyButtonSettings;

              if (redirection.target === 'NONE') {
                redirection.targetId = null;
              }

              if (action.type === 'NONE') {
                action.messages = [];
              }

              action.messages.forEach((msg, messageIndex) => {
                if (msg.type === QuickReplyMessageType.PDF) {
                  msg.type = QuickReplyMessageType.DOCUMENT;
                }
                if (msg.type !== QuickReplyMessageType.TEXT) {
                  if (msg.file) {
                    listFilesToUpload.push({
                      file: msg.file as File,
                      componentIndex,
                      buttonIndex,
                      messageIndex,
                    });
                    delete msg.file;
                  }
                } else {
                  delete msg.url;
                }
              });
            } else {
              delete btn.quickReplyButtonSettings;
            }
          });
        }
      });

      const list: ResponseFile[] = yield all([
        ...listFilesToUpload.map(item => call(uploadFileSaga, item.file)),
      ]);
      listFilesToUpload.forEach((item, index) => {
        const { buttons } = components[item.componentIndex];
        if (buttons) {
          const quickReply = buttons[item.buttonIndex].quickReplyButtonSettings;
          if (quickReply) {
            quickReply.action.messages[item.messageIndex].url = list[index].url;
          }
        }
      });

      if (footerComponent && footer) {
        footer.text = footer.text?.trim();
      }
    }

    if (headerComponent && header) {
      const headerFormat = header.format;

      if (headerFormat === FormatType.TEXT) {
        header = {
          format: FormatType.TEXT,
          text: header?.text?.trim(),
          type: header.type,
        } as WhatsappTemplateComponent;
      } else {
        let file =
          header &&
          header[headerFormat?.toLowerCase() as 'document' | 'image' | 'video'];
        let isFile = typeof file !== 'string';
        if (
          mode === WhatsAppTemplateMode.EDIT &&
          typeof header.url !== 'string'
        ) {
          file = header.url;
          isFile = true;
        }

        if (file && isFile) {
          const { url, mime }: ResponseFile = yield call(uploadFileSaga, file);
          header = {
            format: headerFormat,
            type: header?.type,
            url,
            mime,
          } as WhatsappTemplateComponent;
        }
      }
    }

    if (mode === WhatsAppTemplateMode.CREATE) {
      const { buttons } = components[btnIndex];
      const buttonList = [
        {
          ...components[btnIndex],
        },
      ];

      template = {
        botAccountId: Number(accountId),
        name: formValues.name,
        templateUse: formValues.type,
        category: formValues.category,
        language: formValues.language,
        message: formValues.messageWithPlaceHolders,
        components: [
          ...(headerComponent ? [header] : []),
          ...(footerComponent ? [footer] : []),
          ...(buttons && buttons.length > 0 ? buttonList : []),
        ],
      };
    }

    const response: B2ChatAPI.Response<SaveWhatsAppTemplateState['result']> =
      yield call(
        [
          B2ChatClient.resources.templates.actions,
          mode === WhatsAppTemplateMode.CREATE
            ? 'saveTemplate'
            : 'updateTemplate',
        ],
        {
          ...(mode === WhatsAppTemplateMode.EDIT && {
            params: { botAccountId: accountId, templateName: formValues.name },
          }),
          data:
            mode === WhatsAppTemplateMode.EDIT
              ? {
                  status: formValues.status,
                  components: [...(headerComponent ? [header] : [])],
                }
              : template,
        }
      );

    if (response.error) throw response.error;

    yield put(saveWhatsAppTemplateSuccess(response.data));
    yield put(
      updateWhatsAppTemplateByBotAccountStatus({
        accountId: Number(accountId),
        name: formValues.name,
        status: formValues.status,
      })
    );
  } catch (error) {
    if (isErrorPayload(error)) {
      yield put(saveWhatsAppTemplateFailure({ error }));
    } else if (error instanceof Error) {
      yield put(
        saveWhatsAppTemplateFailure({
          error: { details: error.message, errorCode: error.name },
        })
      );
    } else {
      yield put(
        saveWhatsAppTemplateFailure({
          error: { errorCode: 'OPERATION_FAILED', details: 'operation failed' },
        })
      );
    }
  } finally {
    yield put(saveWhatsAppTemplateFulfill());
  }
}

function* fetchWhatsAppTemplatesByContactIdSaga() {
  const activeChat: ChatTray = yield select(activeChatSelector);
  if (!activeChat?.viaBotAccountId || !activeChat?.contact.id) {
    // eslint-disable-next-line no-console
    console.error('botAccountId and contactId are required!');
  }

  try {
    const response: B2ChatAPI.Response<WhatsAppTemplate[]> = yield call(
      B2ChatClient.resources.templates.actions.getTemplates,
      {
        params: {
          botAccountId: activeChat.viaBotAccountId,
          contactId: activeChat.contact.id,
        },
      }
    );

    if (response.error) throw response.error;

    yield put(fetchWhatsAppTemplatesByContactIdSuccess(response.data));
  } catch (error) {
    if (isErrorPayload(error)) {
      yield put(fetchWhatsAppTemplatesByContactIdFailure({ error }));
    } else {
      yield put(
        fetchWhatsAppTemplatesByContactIdFailure({
          error: {
            errorCode: 'OPERATION_FAILED',
            details: (error as Error).message,
            timestamp: Date.now(),
            traceId: '0',
          },
        })
      );
    }
  } finally {
    yield put(fetchWhatsAppTemplatesByContactIdFulfill());
  }
}

export default function* whatsAppTemplates() {
  yield takeLatest(
    fetchWhatsAppTemplatesByBotAccount,
    fetchWhatsAppTemplatesByBotAccountSaga
  );
  yield takeLatest(
    fetchWhatsAppTemplatesByContactId,
    fetchWhatsAppTemplatesByContactIdSaga
  );
  yield takeLatest(saveWhatsAppTemplate, saveWhatsAppTemplateSaga);
}
